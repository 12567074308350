import * as SlugActions from '../actions/slug.actions';
import { table } from '../../config/config';

const initialState = {
  slug: null,
  serverErrors: {},
  searchAttributes: {
    country_id: '',
    state_id: '',
    searchKey: '',
    is_active: false,
    is_deleted: false,
  },

  table: {
    paginatorConfig: table.paginatorConfig,
    data: [],
    isLoading: false,
  },
  courseReviewsTable: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: { data: [] },
    isLoading: false,
  },
};

export const slugReducer = function (state = initialState, action) {
  switch (action.type) {
    case SlugActions.GET_SLUG:
      return {
        ...state,
        slug: action.payload.slug,
      };
    case SlugActions.EDIT_SLUG:
    case SlugActions.SLUGS_PAGINATION_RESULT: {
      const { paginationResult } = action.payload;
      return {
        ...state,
        ...action.payload,
        table: {
          paginatorConfig: {
            perPage: (paginationResult != undefined ? +paginationResult['per_page'] : 20),
            pages: (paginationResult != undefined? paginationResult['last_page'] : 1),
            index:(paginationResult != undefined ? paginationResult['current_page']: 1)
          },
          paginationResult: paginationResult,
          isLoading: false,
        },
      };
    }
    case SlugActions.PAGINATION_STARTED: {
      return {
        ...state,
        table: {
          paginatorConfig: state.table.paginatorConfig,
          paginationResult: state.table.paginationResult,
          isLoading: true,
        },
      };
    }
    case SlugActions.SLUGS_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
        table: {
          ...state.table,
          isLoading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
