import {api} from "../../../../../config/api";
import {HttpService} from "../../../../../core/service/http.service";

export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const CLEAR_USER_FORM = 'CLEAR_USER_FORM';

export const USERS_PAGINATION_STARTED = 'USERS_PAGINATION_STARTED';
export const USERS_PAGINATION_RESULT = 'USERS_PAGINATION_RESULT';
export const USERS_PAGINATION_ERROR_RESPONSE = "USERS_PAGINATION_ERROR_RESPONSE";



export const USERS_SERVER_ERROR_RESPONSE = 'USERS_SERVER_ERROR_RESPONSE';
export const USER_PAYOUT_PAGINATION_RESULT = 'USER_PAYOUT_PAGINATION_RESULT';
export const PAGINATE_PAYOUT_STARTED = 'PAGINATE_PAYOUT_STARTED';



export const CHANGE_USER_SEARCH_ATTRIBUTES = 'CHANGE_USER_SEARCH_ATTRIBUTES';
export const CLEAR_USER_SEARCH_ATTRIBUTES = 'CLEAR_USER_SEARCH_ATTRIBUTES';
export const GET_USERS_STARTED = 'GET_USERS_STARTED';



export function getUsers(page=1 , perPage=20) {
  return dispatch => {
    dispatch({
      type: GET_USERS_STARTED
    });

    const url = api.USERS + '?page=' + page + '&per_page=' + perPage ;
    //+ '&searchKey=' + searchAttributes.searchKey + '&auto_payout=' + searchAttributes.auto_payout +
    // '&accept_payment=' + (searchAttributes.accept_payment) + '&send_sms=' + (searchAttributes.send_sms) + 
    // '&role_id=' +(searchAttributes.role_id);

    HttpService.prepareGetRequest(url)
      .execute(
        response => {
          dispatch({
            type: GET_USERS,
            payload: {
              users: response['data']
            }
          });
        },
        error => dispatch({
          type: USERS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        })
      );
  }
}

export function paginateUsers(page, perPage,orderBy, orderDir,searchAttributes) {
  return (dispatch) => {
      dispatch({
          type: USERS_PAGINATION_STARTED,
          payload: {}
      });

      const url = api.USERS + '?page=' + page + '&per_page=' + perPage + '&orderBy=' +  `${orderBy === 'row' ? 'id' : orderBy}` + '&orderDir=' + orderDir +
      '&searchKey=' + searchAttributes.searchKey + '&auto_payout=' + (searchAttributes.auto_payout) +
      '&accept_payment=' + (searchAttributes.accept_payment) + '&send_sms=' + (searchAttributes.send_sms) + 
      '&role_id=' +(searchAttributes.role_id);

      HttpService.prepareGetRequest(url).execute(response => {
          dispatch({
              type: USERS_PAGINATION_RESULT,
              payload: {
                  paginationResult: response
              }
          });
      }, error => dispatch({
          type: USERS_PAGINATION_ERROR_RESPONSE,
          payload: {
              serverErrors: error['response']['data']['data']
          }
      }));
  }
}


// export function clearUserSearchAttributes() {
//   return dispatch => dispatch({
//     type: CLEAR_USER_SEARCH_ATTRIBUTES
//   })
// }


export function changeUserSearchAttributes(searchAttributes) {
  return dispatch => {
    dispatch({
      type: CHANGE_USER_SEARCH_ATTRIBUTES,
      payload: {
        searchAttributes: {...searchAttributes}
      }
    });
  };
}


export function getUser(id) {
  return dispatch => HttpService.prepareGetRequest(api.USERS + '/' + id)
    .execute(response => {
      dispatch({
        type: GET_USER,
        payload: {
          user: response['data']
        }
      });
    });
}

export function addUser(body, successCb = null, failureCb = null) {
  return dispatch => HttpService.preparePostRequest(api.USERS, body)
    .execute(
      (response) => {
        dispatch(getUsers());
        if (successCb) {
          successCb(response["data"]);
        }
      },
      error => {
        dispatch({
          type: USERS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      }
    );
}

export function editUser(id, body, successCb = null, failureCb = null) {
  return dispatch => HttpService.preparePutRequest(api.USERS + '/' + id, body)
    .execute(
      (response) => {
        // dispatch(getUsers());
        if (successCb) {
          successCb(response["data"]);
        }
      },
      error => {
        dispatch({
          type: USERS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
}

export function deleteUser(id, successCb = null) {
  return dispatch => HttpService.prepareDeleteRequest(api.USERS + '/' + id)
    .execute(() => {
        successCb();
        // dispatch(getUsers())
      },
      error => dispatch({
        type: USERS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']['data']
        }
      }));
}

export function restoreUser(id, successCb = null) {
  return dispatch => HttpService.prepareGetRequest(api.USERS + '/' + id + '/restore')
    .execute(() => {
        successCb();
        // dispatch(getUsers())
      },
      error => dispatch({
        type: USERS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']['data']
        }
      }));
}

export function getPayoutPerUser(id,page,perPage) {
  return dispatch => {
    dispatch({
      type: PAGINATE_PAYOUT_STARTED
    });
    HttpService.prepareGetRequest(api.USERS + '/' + id + '/payout-bills?page='+page+'&perPage='+perPage)
      .execute(response => {
          dispatch({
            type: USER_PAYOUT_PAGINATION_RESULT,
            payload: {
              paginationResult: response
            }
          });
        },
        error => dispatch({
          type: USERS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        })
      )
  }
}

export function clearUserForm() {
  return dispatch => {
    dispatch({
      type: CLEAR_USER_FORM
    });
  }
}
