import * as billsActions from '../actions/bills.action';
import {table} from "../../../../players/config/config";



const initialState = {
  serverErrors: {},
  table: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: {data: []},
    isLoading: false
  },
  
  searchAttributes: {
    channel: '',
    paid: false,
    not_paid: false,
    voucher_used:false,
    credit_used:false,
    voucher_not_used:false,
    from_paid_at: null,
    to_paid_at: null,
    from_game_at: null,
    to_game_at: null,
    from_payout_at: null,
    to_payout_at: null,
    course_id: '',
    sys_user_id: '',
    payout_status: '',
  },
};



export  const billsReducer = function (state = initialState, action) {
  switch (action.type) {
    case billsActions.GET_BILLS_STARTED:{
      return {
        ...state,
        isLoading: true
      }
    }
    case billsActions.BILLS_PAGINATION_RESULT: {
      const {paginationResult} = action.payload;
      return {
        ...state,
        ...action.payload,
        table: {
          paginatorConfig: {
            perPage: +paginationResult['meta']['per_page'],
            pages: paginationResult['meta']['last_page'],
            index: +paginationResult['meta']['current_page']
          },
          paginationResult: paginationResult,

        },
        total_amount: paginationResult['meta']['total_amount'],
        total_commission: paginationResult['meta']['total_commission'],
        total_vouchers_value: paginationResult['meta']['total_vouchers_value'],
        count_credit_used: paginationResult['meta']['count_credit_used'],
        sum_credit_used: paginationResult['meta']['sum_credit_used'],
        sum_refunded_into_credits: paginationResult['meta']['sum_refunded_into_credits'],
        count_refunded_into_credits: paginationResult['meta']['count_refunded_into_credits'],
        total_used_vouchers: paginationResult['meta']['total_used_vouchers'],
        isLoading: false

      }
    }

    case billsActions.CHANGE_SEARCH_BILL_ATTRIBUTES: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case billsActions.GET_CHANNELS: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case  billsActions.BILLS_SERVER_ERROR_RESPONSE:{
      return{
        ...state,
        serverErrors: action.payload.serverErrors,
        isLoading:false
      }
    }

    case billsActions.REFUND_BILL_SUCCESSFULLY:{
      return{
        ...state,
        ...action.payload,
      }
    }
    default:{
      return state;
    }
  };

}
