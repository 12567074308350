import {api} from "../../../../../config/api";
import {HttpService} from "../../../../../core/service/http.service";

export const GET_ROLE = 'GET_ROLE';
export const GET_ROLES = 'GET_ROLES';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const CLEAR_ROLE_FORM = 'CLEAR_ROLE_FORM';
export const GET_ROLES_STARTED = 'GET ROLES STARTED';
export const ROLES_SERVER_ERROR_RESPONSE = 'ROLES_SERVER_ERROR_RESPONSE';

export function getPermissions() {
  return dispatch => HttpService.prepareGetRequest(api.PERMISSIONS)
    .execute(response => {
        dispatch({
          type: GET_PERMISSIONS,
          payload: {
            permissions: response['data']
          }
        });
      },
      error => dispatch({
        type: ROLES_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']['data']
        }
      }));
}

export function getRoles(onlyActive = false) {
  return dispatch => {
    dispatch({
      type: GET_ROLES_STARTED
    });

    HttpService.prepareGetRequest(api.ROLES + (onlyActive ? '?onlyActive=1' : ''))
      .execute(
        response => {
          dispatch({
            type: GET_ROLES,
            payload: {
              roles: response['data']
            }
          });
        },
        error => dispatch({
          type: ROLES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        }));
  };
}

export function getRole(id) {
  return dispatch => HttpService.prepareGetRequest(api.ROLES + '/' + id)
    .execute(response => {
      dispatch({
        type: GET_ROLE,
        payload: {
          role: response['data']
        }
      });
    });
}

export function addRole(body, successCb = null, failureCb = null) {
  return dispatch => HttpService.preparePostRequest(api.ROLES, body)
    .execute(
      (response) => {
        dispatch(getRoles());
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: ROLES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
}

export function editRole(id, body, successCb = null, failureCb = null) {
  return dispatch => HttpService.preparePutRequest(api.ROLES + '/' + id, body)
    .execute(
      (response) => {
        dispatch(getRoles());
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: ROLES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
}

export function deleteRole(id,successCb=null) {
  return dispatch => HttpService.prepareDeleteRequest(api.ROLES + '/' + id)
    .execute(() => {successCb(); dispatch(getRoles())},
      error => dispatch({
        type: ROLES_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']['data']
        }
      }));
}

export function restoreRole(id,successCb=null) {
  return dispatch => HttpService.prepareGetRequest(api.ROLES + '/' + id + '/restore')
    .execute(() => {successCb(); dispatch(getRoles())},
      error => dispatch({
        type: ROLES_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']['data']
        }
      }));
}

export function clearRoleForm() {
  return dispatch => {
    dispatch({
      type: CLEAR_ROLE_FORM
    });
  }
}
