import {HttpService} from "../../../../core/service/http.service";
import {api} from "../../../../config/api";


export const GET_LEADER_PERIODS_SUCCESS = 'GET_LEADER_PERIODS_SUCCESS';
export const GET_LEADER_PERIODS_STARTED = 'GET_LEADER_PERIODS_STARTED';
export const GET_LEADER_PERIODS_ERROR = 'GET_LEADER_PERIODS_ERROR';
export const GET_LEADER_BOARD_DETAILS_STARTED = 'GET_LEADER_BOARD_DETAILS_STARTED';
export const GET_LEADER_BOARD_DETAILS = 'GET_LEADER_BOARD_DETAILS';
export const GET_LEADER_BOARD_DETAILS_ERROR = 'GET_LEADER_BOARD_DETAILS_ERROR';



export function getLeaderBoardsPeriods() {
  return (dispatch) => {
    dispatch({
      type: GET_LEADER_PERIODS_STARTED
    });
    HttpService.prepareGetRequest(api.LEADER_BOARD_PERIODS).execute(response => {
      dispatch({
        type: GET_LEADER_PERIODS_SUCCESS,
        payload: {
          periods: response['data']
        }
      });
    });
  };
}

export function getLeaderBoardsDetails(fromDate,toDate,countryId,courseId) {


  return (dispatch) => {
    dispatch({
      type: GET_LEADER_BOARD_DETAILS_STARTED
    });
    HttpService.prepareGetRequest(api.LEADER_BOARD_DETAILS+'?from_date='+fromDate+'&to_date='+toDate+(countryId ? '&country_id='+countryId : '')+(courseId ? '&course_id='+courseId : '')).execute(response => {
      dispatch({
        type: GET_LEADER_BOARD_DETAILS,
        payload: {
          results: response['data'],
          total:response['total'],
          countryId:countryId
        }
      });
    });
  };
}
