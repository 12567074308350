import axios from 'axios';
import {HttpRequest} from "../models/HttpRequest";

export class HttpService {

    static prepareGetRequest(url) {
        return new HttpRequest(axios({
            method: 'get',
            url: url
        }));
    };

    static preparePostRequest(url, data) {
        return new HttpRequest(axios({
            method: 'post',
            url: url,
            data: data,
        }));
    };

    static preparePutRequest(url, data) {
        return new HttpRequest(axios({
            method: 'put',
            url: url,
            data: data
        }));
    };

    static prepareDeleteRequest(url, data=[]) {
        return new HttpRequest(axios({
            method: 'delete',
            url: url,
            data:data
        }));
    };
    
}
