import { HttpService } from '../../../../core/service/http.service';
import { api } from '../../../../config/api';
import * as dateHelper from '../../../../core/helpers/date.helper';

export const PAGINATION_STARTED = 'PAGINATION_STARTED';
export const PAGINATE_PACKAGES_ERROR_RESPONSE =
  'PAGINATE_PACKAGES_ERROR_RESPONSE';
export const PACKAGES_PAGINATION_RESULT = 'PACKAGES_PAGINATION_RESULT';
export const CREATE_PACKAGE = 'CREATE_PACKAGE';
export const PACKAGE_SERVER_ERROR_RESPONSE = 'PACKAGE_SERVER_ERROR_RESPONSE';
export const GET_PACKAGE = 'GET_PACKAGE';
export const EDIT_PACKAGE = 'EDIT_PACKAGE';

export function paginatePackages(
  page,
  perPage,
  orderBy,
  orderDir,
  searchAttributes
) {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_STARTED,
      payload: {},
    });
    const url =
      api.PACKAGES +
      '?page=' +
      page +
      '&per_page=' +
      perPage +
      '&orderBy=' +
      orderBy +
      '&orderDir=' +
      orderDir +
      '&country_id=' +
      searchAttributes.country_id +
      '&state_id=' +
      searchAttributes.state_id +
      '&search_key=' +
      searchAttributes.searchKey +
      '&is_active=' +
      searchAttributes.is_active +
      '&is_deleted=' +
      searchAttributes.is_deleted;
    HttpService.prepareGetRequest(url).execute(
      (response) => {
        dispatch({
          type: PACKAGES_PAGINATION_RESULT,
          payload: {
            paginationResult: response,
          },
        });
      },
      (error) =>
        dispatch({
          type: PAGINATE_PACKAGES_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data'],
          },
        })
    );
  };
}

export function createPackage(packages, successCb = null, failureCb = null) {
  return (dispatch) => {
    HttpService.preparePostRequest(api.PACKAGES, packages).execute(
      (response) => {
        dispatch({
          type: CREATE_PACKAGE,
          payload: {
            serverErrors: {},
          },
        });
        if (successCb) {
          successCb(response);
        }
      },
      (error) => {
        dispatch({
          type: PACKAGE_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data'],
          },
        });
        if (failureCb) {
          failureCb();
        }
      }
    );
  };
}

export function getPackageById(id) {
  return (dispatch) => {
    HttpService.prepareGetRequest(`${api.PACKAGES}/${id}`).execute(
      (response) => {
        dispatch({
          type: GET_PACKAGE,
          payload: {
            packages: response.data,
          },
        });
      }
    );
  };
}

// export function getPackageById(id) {
//   return dispatch => {
//     HttpService.prepareGetRequest(`${api.PACKAGES}/${id}`).execute(response => {
//       dispatch({
//         type: GET_PACKAGE,
//         payload: {
//           packages : response['data']
//         }
//       })
//     })
//   }
// }

export function editPackage(id, packages, successCb = null, failureCb = null) {
  return (dispatch) => {
    HttpService.preparePutRequest(`${api.PACKAGES}/${id}`, packages).execute(
      (response) => {
        dispatch({
          type: EDIT_PACKAGE,
          payload: {
            packages: response['data'],
            serverErrors: {},
          },
        });
        if (successCb) {
          successCb(response);
        }
      },
      (error) => {
        dispatch({
          type: PACKAGE_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data'],
          },
        });
        if (failureCb) {
          failureCb();
        }
      }
    );
  };
}

export function deletePackage(id, successCb = null) {
  return (dispatch) =>
    HttpService.prepareDeleteRequest(api.PACKAGES + '/' + id).execute(() => {
      successCb();
    });
}

export function uploadPackagePhoto(imageBase64, packages, form) {
  return (dispatch) =>
    HttpService.preparePostRequest(
      `${api.UPLOAD_PHOTO_Thumbnail}?model=package&id=${packages.id}`,
      imageBase64
    ).execute(
      (response) => {
        packages.image =
          packages.image === null
            ? response['data']['image_url']
            : packages.image + '|' + response['data']['image_url'];
        form.setFieldValue('image', packages.image);
        console.log(packages.image.split('|'));
      },
      (error) => {
        dispatch({
          type: PACKAGE_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data'],
          },
        });
      }
    );
}
