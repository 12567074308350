import {api} from "../../../../../config/api";
import {HttpService} from "../../../../../core/service/http.service";

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_COUNTRIES_STARTED = 'GET_COUNTRIES_STARTED';
export const COUNTRIES_SERVER_ERROR_RESPONSE = 'COUNTRIES_SERVER_ERROR_RESPONSE';
export const CLEAR_COUNTRY_FORM = 'CLEAR_COUNTRY_FORM';
export const COUNTRY_DELETED = 'Country Deleted Successfully';
export const COUNTRY_RESTORED = 'Country Restored Successfully';

export function getCountriesList() {
  return dispatch => {
    dispatch({
      type: GET_COUNTRIES_STARTED
    });
    HttpService.prepareGetRequest(api.COUNTRIES).execute(response => {
        dispatch({
          type: GET_COUNTRIES,
          payload: {
            countries: response['data']
          }
        });
      },
      error => dispatch({
        type: COUNTRIES_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      }))
  };
}

export function getCountry(id) {
  return dispatch => HttpService.prepareGetRequest(api.COUNTRIES + '/' + id)
    .execute(response => {
      dispatch({
        type: GET_COUNTRY,
        payload: {
          country: response['data']
        }
      });
    });
}


export function addCountry(body, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePostRequest(api.COUNTRIES, body).execute((response) => {
        dispatch(getCountriesList());
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: COUNTRIES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      })
  };
}

export function editCountry(id, body, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePutRequest(api.COUNTRIES + '/' + id, body).execute((response) => {
        dispatch(getCountriesList());
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: COUNTRIES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      })
  };
}

export function deleteCountry(id,successCb = null) {
  return dispatch => {
    HttpService.prepareDeleteRequest(api.COUNTRIES + '/' + id).execute(() => {
        dispatch({type: COUNTRY_DELETED});
        dispatch(getCountriesList());
        successCb();
      },
      error => dispatch({
        type: COUNTRIES_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      }))
  };
}

export function restoreCountry(id,successCb = null) {
  return dispatch => {
    HttpService.prepareGetRequest(api.COUNTRIES + '/' + id + '/restore').execute(() => {
        dispatch({type: COUNTRY_RESTORED});
        dispatch(getCountriesList());
        successCb();
      },
      error => dispatch({
        type: COUNTRIES_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      }))
  };
}

export function clearCountryForm() {
  return dispatch => {
    dispatch({
      type: CLEAR_COUNTRY_FORM
    });
  }
}
