import React from "react";
import * as Yup from "yup";

export const table = {
    paginatorConfig: {
        perPage: 20,
        pages: 1,
        index: 1
    },
    pageSizeOptions: [20, 50, 75, 100]
};

export const createFaqSchema = Yup.object().shape({
  title: Yup.string().required(),
  question: Yup.string().required(),
  answer: Yup.string().required("Answer is a required field"),//Yup.string().required("Answer is a required field"),
  priority: Yup.number().required("Priority is a required field"),
  is_published: Yup.boolean(),
});

export const manageFaqSchema = Yup.object().shape({
  searchKey: Yup.string()
      .min(2),
});



