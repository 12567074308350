import * as Yup from "yup";
import {Badge} from "reactstrap";
import React from "react";

export const table = {
  paginatorConfig: {
    perPage: 50,
    pages: 1,
    index: 1
  },
  pageSizeOptions: [20,50, 100, 500,1000,2000]
};

export const managePlayersSchema = Yup.object().shape({
  searchKey: Yup.string()
    .min(2),
});

export const srcIcons = {
  'password':<i title="Normal (Password)" className="fas fa-key"/> ,
  'facebook.com' :<i title="Facebook" className={'fab fa-facebook'}/>   ,
  'google.com' : <i title="Google" className={'fab fa-google'}/>,
  'apple.com' :<i title="Apple" className="fab fa-apple"/>,
};
