import {HttpService} from "../../../../core/service/http.service";
import {api} from "../../../../config/api";
import {setUserData} from "../../../authentication/store/actions/user.actions";

export const PROFILE_SERVER_ERROR_RESPONSE = 'PROFILE_SERVER_ERROR_RESPONSE';
export const GET_PROFILE_DATA_SUCCESSFULLY = 'GET_PROFILE_DATA_SUCCESSFULLY';

export function changePassword(requestBody, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePostRequest(api.CHANGE_PASSWORD, requestBody)
      .execute(
        () => {
          if (successCb) {
            successCb();
          }
          dispatch({
            type: PROFILE_SERVER_ERROR_RESPONSE,
            payload: {
              serverErrors: {}
            }
          });
        },
        error => {
          dispatch({
            type: PROFILE_SERVER_ERROR_RESPONSE,
            payload: {
              serverErrors: error['response']['data']['data']
            }
          });
          if (failureCb) {
            failureCb();
          }
        }
      );
  };
}

export function getProfile() {
  return dispatch => {
    HttpService.prepareGetRequest(api.MYSELF)
      .execute(
        response => dispatch({
          type: GET_PROFILE_DATA_SUCCESSFULLY,
          payload: response["data"]
        }),
        error => {
          dispatch({
            type: PROFILE_SERVER_ERROR_RESPONSE,
            payload: {
              serverErrors: error['response']['data']['data']
            }
          })
        }
      );
  };
}

export function editProfile(id, body, successCb = null, failureCb = null) {
  return dispatch => HttpService.preparePutRequest(api.USERS + '/' + id, body)
    .execute(
      () => {
        dispatch(getProfile());
        if (successCb) {
          successCb();
        }
      },
      error => {
        dispatch({
          type: PROFILE_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
}

export function uploadImage(imageBase64, user,successCb= null) {
  return dispatch => HttpService.preparePostRequest(api.UPLOAD_PHOTO, imageBase64)
    .execute(
      (response) => {
        user.profile_picture = response ? response["data"]["image_url"] : '';
        user.role = user.roles[0];
        dispatch(setUserData(user));
        dispatch(editProfile(user.id, user));
        successCb();
      },
      error => {
        dispatch({
          type: PROFILE_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
      });
}

