
import {api} from '../../../../config/api';
import { HttpService } from '../../../../core/service/http.service';
import * as dateHelper from "../../../../core/helpers/date.helper";


export const FAQ_PAGINATION_STARTED = 'FAQ_PAGINATION_STARTED ';
export const FAQS_PAGINATION_RESULT = 'FAQS_PAGINATION_RESULT';
export const FAQS_PAGINATION_ERROR_RESPONSE = 'PAGINATE_GAMES_ERROR_RESPONSE';
export const FAQS_GET_BILLS_STARTED = 'GET_BILLS_STARTED';
export const FAQS_GET_BILLS_SUCCESSFULLY = 'GET_BILLS_SUCCESSFULLY';
export const FAQS_BILLS_ERROR_RESPONSE = 'BILLS_SERVER_ERROR_RESPONSE';
export const GET_FAQ = 'GET_FAQ';
export const CLEAR_FAQ_DETAILS = 'CLEAR_FAQ_DETAILS';
export const GET_FAQ_ERROR_RESPONSE = 'GET_FAQ_ERROR_RESPONSE';


export const EDIT_FAQ = 'EDIT_FAQ';
export const FAQ_SERVER_ERROR_RESPONSE = 'FAQ_SERVER_ERROR_RESPONSE';


export const GET_FAQ_LOGS_SUCCESSFULLY = 'GET_FAQ_LOGS_SUCCESSFULLY';
export const FAQ_LOGS_SERVER_ERROR_RESPONSE = "FAQ_LOGS_SERVER_ERROR_RESPONSE";
export const CLEAR_FAQ_LOGS = "CLEAR_FAQ_LOGS";


export const CHANGE_FAQ_SEARCH_ATTRIBUTES = 'CHANGE_FAQ_SEARCH_ATTRIBUTES';
export const CLEAR_FAQ_SEARCH_ATTRIBUTES = 'CLEAR_FAQ_SEARCH_ATTRIBUTES';


export function paginateFaqs(page, perPage,orderBy, orderDir,searchAttributes) {
    return (dispatch) => {
        dispatch({
            type: FAQ_PAGINATION_STARTED,
            payload: {}
        });

        const url = api.FAQ + '?page=' + page + '&per_page=' + perPage + '&orderBy=' + orderBy + '&orderDir=' + orderDir +
        '&started_at=' + (searchAttributes.fromDate ? dateHelper.format(searchAttributes.fromDate, 'YYYY-MM-DD 00:00:00') : '') +
        '&expired_at=' + (searchAttributes.toDate ? dateHelper.format(searchAttributes.toDate, 'YYYY-MM-DD 00:00:00') : '') +
        '&searchKey=' + searchAttributes.searchKey + '&new_user=' + (searchAttributes.new_user);

        HttpService.prepareGetRequest(url).execute(response => {
            dispatch({
                type: FAQS_PAGINATION_RESULT,
                payload: {
                    paginationResult: response
                }
            });
        }, error => dispatch({
            type: FAQS_PAGINATION_ERROR_RESPONSE,
            payload: {
                serverErrors: error['response']['data']['data']
            }
        }));
    }
}


export function clearFaqSearchAttributes() {
  return dispatch => dispatch({
    type: CLEAR_FAQ_SEARCH_ATTRIBUTES
  })
}


export function changeSearchFaqAttributes(searchAttributes) {
  return dispatch => {
    dispatch({
      type: CHANGE_FAQ_SEARCH_ATTRIBUTES,
      payload: {
        searchAttributes: {...searchAttributes}
      }
    });
  };
}

export function getFaqById(id) {
  const url = api.FAQ + '/' + id;

  return (dispatch) => {
    HttpService.prepareGetRequest(url).execute(response => {
      dispatch({
        type: GET_FAQ,
        payload: {
          faq: response['data']
        }
      })
    }, error => {
      dispatch({
      type: GET_FAQ_ERROR_RESPONSE,
      payload: {
          serverErrors: error['response']['data']['data']
      }
  })
})
  }
}

export function clearFaqDetails() {
  return dispatch => dispatch({
    type: CLEAR_FAQ_DETAILS
  });
}

export function getFaqBills(faqId, page = 1 , perPage = 20) {
    return dispatch => {
      dispatch({
        type: FAQS_GET_BILLS_STARTED
      });


      HttpService.prepareGetRequest(api.FAQ_BILLS.replace('{id}',faqId) +'?page='+page +'&per_page='+perPage ).execute(response => {
        dispatch({
            type:FAQS_GET_BILLS_SUCCESSFULLY,
            payload: {
              paginationResult: response
            }
          });

        },
        error => dispatch({
          type: FAQS_BILLS_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']



          }
        })
      );
    }
  }

  export function getFaqLogs(faqId) {
    return dispatch => {


      HttpService.prepareGetRequest(api.FAQ_LOGS.replace('{id}', faqId)).execute(response => {
          dispatch({
            type: GET_FAQ_LOGS_SUCCESSFULLY,
            payload: {
              logs: response['data']
            }
          });

        },
        error => dispatch({
          type: FAQ_LOGS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['data']



          }
        })
      );
    }
  }





  export function createFaq(faq, successCb = null, failureCb = null) {
    return (dispatch) => {
      HttpService.preparePostRequest(api.FAQ, faq).execute(
        (response) => {
          if (successCb) {
            successCb(response);
          }
        },
        error => {
          dispatch({
            type: FAQ_SERVER_ERROR_RESPONSE,
            payload: {
              serverErrors: error['response']['data']['data']
            }
          });
          if (failureCb) {
            failureCb();
          }
        });
    };
  }

  export function editFaq(id, faq, successCb = null, failureCb = null) {
    return dispatch => {
      HttpService.preparePutRequest(`${api.FAQ}/${id}`, faq).execute(response => {
          dispatch({
            type: EDIT_FAQ,
            payload: {
              faq: response['data']
            }
          });
          if (successCb) {
            successCb(response);
          }
        },
        error => {
          dispatch({
            type: FAQ_SERVER_ERROR_RESPONSE,
            payload: {
              serverErrors: error['response']['data']['data']
            }
          });
          if (failureCb) {
            failureCb();
          }
        });
    }
  }




  export function clearFaqLogs(){
    return dispatch => dispatch({
      type: CLEAR_FAQ_LOGS
    });
  }

  export function deleteFaq(id, cb) {
    return dispatch => HttpService.prepareDeleteRequest(api.FAQ + '/' + id)
      .execute(() => cb())
  }



  export function restoreFaq(id, cb) {
    return dispatch => HttpService.prepareGetRequest(api.FAQ + '/' + id + '/restore')
      .execute(() => cb());
  }


