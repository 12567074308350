import moment from 'moment';

export function format(date, format) {
  return moment(date).format(format);
}

export function convertUtcToLocalDate(val) {
  const date = new Date(val); // val is in UTC
  const localOffset = date.getTimezoneOffset() * 60000;
  const localTime = date.getTime() - localOffset;

  date.setTime(localTime);
  return date;
}

export function convertLocalToUtc(val) {
  const date = new Date(val);
  const localOffset = date.getTimezoneOffset() * 60000;
  const utcTime = date.getTime() + localOffset;

  date.setTime(utcTime);
  return date;
}

/**
 * True if d1 is after d2
 * @param d1
 * @param d2
 */
export function compareDate(d1, d2){
    // return d1.getTime() > d2.getTime(); // causes error sometimes . 
    return new Date(d1).getTime() > new Date(d2).getTime();
}
