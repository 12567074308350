import {HttpService} from "../../../../core/service/http.service";
import {api} from "../../../../config/api";
import { func } from "prop-types";


export const GET_FAILED_JOBS = 'GET_FAILED_JOBS';
export const FAILED_JOBS_ERROR_RESPONSE = 'FAILED_JOBS_ERROR_RESPONSE'
export const GET_FAILED_jOB_By_ID = "GET_FAILED_jOB_By_ID";

export function paginateFailedJobs(page = 1) {
    return (dispatch) => {

        const url = api.FAILED_JOBS + '?page=' + page ;

        HttpService.prepareGetRequest(url).execute((res) => {
            dispatch({
                type: GET_FAILED_JOBS,
                payload: {
                    paginationResult: res
                }
            })
        },
        (error) => {
            dispatch({
                type: FAILED_JOBS_ERROR_RESPONSE,
                payload: {
                  serverErrors: error['response']['data']['data']
                }
              })
        })
    }
}

export function getFailedJobById(failedJobId) {
    return(dispatch) => {
        const url = api.FAILED_JOBS + "/" + failedJobId;
        HttpService.prepareGetRequest(url).execute((response) => {
            dispatch({
                type: GET_FAILED_jOB_By_ID,
                payload: {
                failedJob: response['data']
                }            
            })
        }, 
        (error) => {
            console.log(error);
        })
    }
}

export function deleteFailedJobById(failedJobId, cb) {
    return(dispatch) => {
        const url = api.FAILED_JOBS + "/" + failedJobId;
        HttpService.prepareDeleteRequest(url).execute(() => cb());

    }
}
