import React from 'react';
import axios from "axios";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {actions} from "../../../shared/store/actions";
import {BaseComponent} from "../base/base.component";
import {TokenService} from "../../service/token-service";
import {client} from "../../../config/client";
import {toastr} from "react-redux-toastr";

const defaultSuccessMsg = 'Success!';

class ServerErrorHandlerComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.setResponseInterceptors();
  }


  state = {
    errorData: {}
  }

  setResponseInterceptors() {
    axios.interceptors.request.use(function (config) {
      const token = TokenService.getToken();
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      return config;
    });


    axios.interceptors.response.use(response => {
        if(response['data']['message'] && response['data']['message'] != defaultSuccessMsg){
          toastr.success('Success', response['data']['message']);
        }
        return response['data'];
      },
      (error) => {
        this.handle(error);
        throw error;
      });
  }

  handle = (error) => {
    
    const {history, removeUserData} = this.props;
    
    this.setState(prevState => {
      return{
        ...prevState,
        errorData: error['response']['data']['data']
      }
    });

    let text = '';
    if (!navigator.onLine) {
      text = 'No internet connection';
    } else if (error['response']['status'] === 401) {
      /**
       * Unauthorized
       */
      TokenService.logout();
      removeUserData();
      history.push({
        pathname: client.LOGIN
      });
      text = error['response']['data']['message'];
    } else if (error['response']['status'] === 403) {
      /**
       * Forbidden
       */
      text = error['response']['data']['message'];
      
    } else if (error['response']['status'] === 404) {
      /**
       * Not Found
       */
      text = error['response']['data']['message'];
    } else if (error['response']['status'] === 405) {
      /**
       * Method not allowed
       */
      text = 'Method is not allowed';
    } else if (error['response']['status'] === 422) {
      /**
       * Unprocessable Entity
       */
      text = error['response']['data']['message'];
    }
    //TODO: Display toast to show error message
    toastr.error('Error', text, {
      timeOut: 0,
      component: () => {
        return(<div>{this.showErrorData()}</div>)
      }
    });
  };

  showErrorData =  () => {
    let elements = [];
    for (const data in this.state.errorData) {
      if (Object.hasOwnProperty.call(this.state.errorData, data)) {
        elements.push(<div style={{marginTop:'10px'}}>{data}{":"}{this.state.errorData[data]}</div>)
      }
    }
    return elements;
  }


  render() {
    const {children} = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ...actions
  }, dispatch);
}

export const ServerErrorHandler = withRouter(connect(null, mapDispatchToProps)(ServerErrorHandlerComponent));
