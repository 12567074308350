export class HttpRequest {

    constructor(request) {
        this.request = request;
        this.execute = this.execute.bind(this);
    }

    /**
     * @param resolve
     * @param reject
     * @returns {void}
     */
    async execute(resolve, reject = null, finallyCb = null) {
        try {
            const response = await this.request;
            resolve(response);
        } catch (error) {
            reject && reject(error);
        } finally {
            finallyCb && finallyCb();

        }
    }
}

