import * as CourseActions from '../actions/course.actions';
import {table} from "../../config/config";
import * as gamesActions from "../../../games/store/actions/game.actions";


const initialState = {
  course: null,
  courseOverallReview: {},
  serverErrors: {},
  searchAttributes: {
    country_id: '',
    state_id: '',
    searchKey: '',
    has_driving_range: false,
    is_standard: false,
    is_deleted: false,
    is_active: false,
    is_featured:false,
  },

  table: {
    paginatorConfig: table.paginatorConfig,
    data: [],
    isLoading: false
  },
  courseReviewsTable: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: {data:[]},
    isLoading: false
  }
};

export const courseReducer = function (state = initialState, action) {

  switch (action.type) {
    case CourseActions.GET_COURSE:
    case CourseActions.EDIT_COURSE:
    case CourseActions.CREATE_COURSE:
    case CourseActions.CHANGE_SEARCH_COURSE_ATTRIBUTES:
    {
      return {
        ...state,
        ...action.payload
      }
    }
    case CourseActions.CLEAR_COURSE_SEARCH_ATTRIBUTES: {
      return {
        ...state,
        searchAttributes: initialState.searchAttributes
      }
    }
    case CourseActions.CLEAR_COURSE_FORM_DATA: {
      return {
        ...state,
        course: null
      }
    }
    case CourseActions.COURSES_PAGINATION_RESULT:
    {
      const {paginationResult} = action.payload;

      return {
        ...state,
        ...action.payload,
        table: {
          paginatorConfig: {
            perPage: +paginationResult['per_page'],
            pages: paginationResult['last_page'],
            index: paginationResult['current_page']
          },
          paginationResult: paginationResult,
          isLoading: false
        }
      }
    }
    case CourseActions.PAGINATION_STARTED:
    {
      return {
        ...state,
        table: {
          paginatorConfig: state.table.paginatorConfig,
          paginationResult: state.table.paginationResult,
          isLoading: true
        }
      }
    }
    case CourseActions.PAGINATE_COURSES_ERROR_RESPONSE:
    {
      return{
        ...state,
        ...action.payload,
        table: {
          ...state.table,
          isLoading: false
        }
      }
    }
    case CourseActions.GET_COURSE_REVIEWS_STARTED: {
      return {
        ...state,
        reviewsTable: {
          paginatorConfig: state.courseReviewsTable.paginatorConfig,
          paginationResult: state.courseReviewsTable.paginationResult,
        },
        isLoading: true,
        

      }
    }

    case CourseActions.GET_COURSE_REVIEWS_SUCCESSFULLY: {
      const {paginationResult} = action.payload;

      return {
        ...state,
        courseReviewsTable: {
          paginatorConfig: {
            perPage: +paginationResult['meta']['per_page'],
            pages: paginationResult['meta']['last_page'],
            index: +paginationResult['meta']['current_page']
          },
          paginationResult: paginationResult,

        },
        isLoading: false,
        courseOverallReview: paginationResult['course']
      }
    }


    case CourseActions.COURSE_REVIEWS_SERVER_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload
      };
    }

    default: {
      return state
    }
  }
};
