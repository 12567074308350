import {api} from "../../../../config/api";
import * as UserActions from '../actions/user.actions';
import {HttpService} from "../../../../core/service/http.service";
import {TokenService} from "../../../../core/service/token-service";

export const LOGIN_ERROR_RESPONSE = 'LOGIN_ERROR_RESPONSE';

/**
 * LogIn user http request
 */
export function logIn(credentials, successCb = null, failureCb = null) {
  return dispatch => HttpService.preparePostRequest(api.LOGIN, credentials)
    .execute(
      response => {
        dispatch(UserActions.setUserData(response['data']));
        TokenService.storeToken(response['data']['token']);
        if (successCb) {
          successCb(response['data']);
        }
      },
      error => {
        dispatch({
          type: LOGIN_ERROR_RESPONSE,
          payload: {
            serverErrors: error
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
}


/**
 * Logout user http request
 * @returns {function(*): *}
 */
export function logOut() {
  return dispatch => HttpService.preparePostRequest(api.LOGOUT, {})
    .execute(() => {
      dispatch(UserActions.removeUserData());
    });
}
