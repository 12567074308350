import * as failedJobsActions from '../actions/failed-jobs.actions';
import {table} from "../../config/config";

const initialState = {
    serverErrors: {},
    failedJob: null,
    table: {
        paginatorConfig: table.paginatorConfig,
        paginationResult: {data: []},
        isLoading: false
      }
}

export const failedJobsReducer = function(state = initialState, action) {
    switch(action.type) {
        case failedJobsActions.GET_FAILED_JOBS: {
            const {paginationResult} = action.payload;
            return {
                ...state,
                ...action.payload,
                table: {
                  paginatorConfig: {
                    perPage: +paginationResult['meta']['per_page'],
                    pages: paginationResult['meta']['last_page'],
                    index: paginationResult['meta']['current_page']
                  },
                  paginationResult: paginationResult,
                  isLoading: false
                }
              }
        }

        case failedJobsActions.GET_FAILED_jOB_By_ID: {
          return {
            ...state,
            failedJob: action.payload.failedJob
          }
        }
        

        default: {
            return state
          }
    }
}