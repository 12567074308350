import React from "react";
import * as Yup from "yup";

export const table = {
    paginatorConfig: {
        perPage: 20,
        pages: 1,
        index: 1
    },
    pageSizeOptions: [20, 50, 75, 100]
};

export const createVoucherSchema = Yup.object().shape({
  code: Yup.string().required(),
  discount: Yup.number().required(),
  total_redeemed_voucher: Yup.number().required("Max redeemed users is a required field"),
  max_times_redeemed_per_user: Yup.number().required("Max times redeemed per user is a required field"),
  new_user: Yup.boolean().required(),
  gf_sponsored: Yup.boolean().required(),
  started_at: Yup.string().required(),
  expired_at: Yup.string().required(),
  game_ids: Yup.array(),
  user_ids: Yup.array(),
  redemption_platform: Yup.string().required(),
  min_purchase:Yup.number().nullable(),
});

export const manageVoucherSchema = Yup.object().shape({
  searchKey: Yup.string()
      .min(2),
});


export const redemptionPlatform = {
    "all" : "App + Web",
    "web" : "Web",
    "app" : "App"
};



