import {
  GET_LEADER_BOARD_DETAILS,
  GET_LEADER_BOARD_DETAILS_STARTED, GET_LEADER_PERIODS_STARTED,
  GET_LEADER_PERIODS_SUCCESS
} from "../actions/leaderboard.action";

const initialState = {
  periods:[],
  results:[],
  total:null,
  isLoading: false,
  filteredCountryId:null

};


export const leaderBoardReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_LEADER_PERIODS_SUCCESS:{
      return {
        ...initialState,
        ...action.payload,
        isLoading: false

      }
    }
    case GET_LEADER_BOARD_DETAILS_STARTED:
    case GET_LEADER_PERIODS_STARTED:{
      return {
        ...initialState,
        isLoading: true
      }
    }
    case GET_LEADER_BOARD_DETAILS:{
      return {
        ...initialState,
        results:action.payload.results,
        total:action.payload.total,
        filteredCountryId:action.payload.countryId,
        isLoading: false

      }
    }
    default:{
      return state;
    }
  }

}
