import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {TokenService} from "../../../../core/service/token-service";
import {actions} from "../../../../shared/store/actions";
import {BaseComponent} from "../../../../core/components/base/base.component";
import {withRouter} from 'react-router-dom';

class AuthComponent extends BaseComponent {
  componentDidMount() {
    this.requestUser();
  }

  requestUser() {
    const {mySelf} = this.props;
    if (TokenService.getToken()) { // if user login for first time
      mySelf();
    }
  }

  render() {
    const {children} = this.props;
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      ...actions
    },
    dispatch);
}

export const Auth = withRouter(connect(null, mapDispatchToProps)(AuthComponent));
