import { table } from "../../config/config";
import * as playersActions from "../actions/player.actions";
import * as moment from "moment";

const initialState = {
  player: null,

  playerGames: null,
  playerCourses: null,

  serverErrors: {},

  receivedScores: null,

  searchAttributes: {
    country_id: "",
    searchKey: "",
    is_sms_verified: true,
    password_resets: false,
    fromDate: null,
    toDate: null,
  },
  playerCredit: {
    paginatorConfig: table.paginatorConfig,
    historyLogs: { data: [] },
    creditSummary: {},
    isLoading: false,
  },

  table: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: { data: [] },
    isLoading: false,
  },

  gamesTable: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: { data: [] },
    isLoading: false,
  },

  billsTable: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: { data: [] },
    isLoading: false,
  },

  scoresTable: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: { data: [] },
    isLoading: false,
  },
  reviewsTable: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: { data: [] },
    isLoading: false,
  },
  playerReferral: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: { data: [] },
    isLoading: false,
  },
};

export const playerReducer = function (state = initialState, action) {
  switch (action.type) {
    case playersActions.GET_PLAYER_CREDIT_STARTED: {
      return {
        ...state,
        playerCredit: {
          paginatorConfig: state.playerCredit.paginatorConfig,
          historyLogs: state.playerCredit.historyLogs,
          creditSummary: {},
          isLoading: true,
        },
      };
    }

    case playersActions.GET_PLAYER_CREDIT: {
      const { playerCredit } = action.payload;
      return {
        ...state,
        playerCredit: {
          paginatorConfig: {
            perPage: +playerCredit["meta"]["per_page"],
            pages: +playerCredit["meta"]["last_page"],
            index: +playerCredit["meta"]["current_page"],
          },
          historyLogs: playerCredit,
          creditSummary: playerCredit["header"],
          isLoading: false,
        },
      };
    }

    case playersActions.CLEAR_CREDIT_DETAILS: {
      return {
        ...state,
        playerCredit: {
          paginatorConfig: state.playerCredit.paginatorConfig,
          historyLogs: { data: [] },
          creditSummary: {},
          isLoading: false,
        },
      };
    }

    case playersActions.PLAYERS_CREDIT_SERVER_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
        playerCredit: {
          paginatorConfig: state.playerCredit.paginatorConfig,
          historyLogs: state.playerCredit.historyLogs,
          creditSummary: state.playerCredit.creditSummary,
          isLoading: false,
        },
      };
    }

    // Referral Reducers
    case playersActions.GET_PLAYER_REFERRAL_STARTED: {
      return {
        ...state,
        playerReferral: {
          paginatorConfig: state.playerReferral.paginatorConfig,
          paginationResult: state.playerReferral.paginationResult,
          isLoading: true,
        },
      };
    }

    case playersActions.GET_PLAYER_REFERRAL_HISTORY: {
      const { playerReferral } = action.payload;
      return {
        ...state,
        playerReferral: {
          paginatorConfig: {
            perPage: +playerReferral["meta"]["per_page"],
            pages: +playerReferral["meta"]["last_page"],
            index: +playerReferral["meta"]["current_page"],
          },
          paginationResult: playerReferral,
          isLoading: false,
        },
      };
    }

    case playersActions.CLEAR_REFERRAL_DETAILS: {
      return {
        ...state,
        playerReferral: {
          paginatorConfig: state.playerReferral.paginatorConfig,
          paginationResult: { data: [] },
          isLoading: false,
        },
      };
    }

    case playersActions.REFERRAL_HISTORY_SERVER_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
        playerReferral: {
          paginatorConfig: state.playerReferral.paginatorConfig,
          paginationResult: state.playerReferral.paginationResult,
          isLoading: false,
        },
      };
    }


    case playersActions.PLAYERS_SERVER_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case playersActions.PLAYERS_CHANGE_SEARCH_ATTRIBUTES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case playersActions.PLAYERS_PAGINATION_RESULT: {
      const { paginationResult } = action.payload;
      return {
        ...state,
        ...action.payload,
        table: {
          paginatorConfig: {
            perPage: +paginationResult["per_page"],
            pages: paginationResult["last_page"],
            index: +paginationResult["current_page"],
          },
          paginationResult: paginationResult,
          isLoading: false,
        },
      };
    }
    case playersActions.PLAYERS_PAGINATION_STARTED: {
      return {
        ...state,
        table: {
          paginatorConfig: state.table.paginatorConfig,
          paginationResult: state.table.paginationResult,
          isLoading: true,
        },
      };
    }
    case playersActions.PLAYERS_PAGINATE_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
        table: {
          ...state.table,
          isLoading: false,
        },
      };
    }
    case playersActions.GET_PLAYER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case playersActions.CLEAR_PLAYER_DETAILS: {
      return {
        ...state,
        player: null,
        playerGames: null,
        playerCourses: null,
      };
    }
    case playersActions.GET_PLAYER_GAMES_STARTED: {
      return {
        ...state,
        gamesTable: {
          paginatorConfig: state.gamesTable.paginatorConfig,
          paginationResult: state.gamesTable.paginationResult,
          isLoading: true,
        },
      };
    }

    case playersActions.GET_PLAYER_GAMES: {
      const { paginationResult } = action.payload;

      return {
        ...state,
        gamesTable: {
          paginatorConfig: {
            perPage: +paginationResult["per_page"],
            pages: paginationResult["last_page"],
            index: +paginationResult["current_page"],
          },
          paginationResult: paginationResult,
          isLoading: false,
        },
      };
    }
    case playersActions.GET_PLAYER_COURSES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case playersActions.CLEAR_MANAGE_PLAYERS_DATA: {
      return {
        ...state,
        searchAttributes: initialState.searchAttributes,
      };
    }
    case playersActions.CLEAR_PLAYER_FORM: {
      return {
        ...state,
        player: null,
        serverErrors: null,
      };
    }

    case playersActions.GET_RECEIVED_SCORES_STARTED: {
      return {
        ...state,
        scoresTable: {
          paginatorConfig: state.scoresTable.paginatorConfig,
          paginationResult: state.scoresTable.paginationResult,
          isLoading: true,
        },
      };
    }

    case playersActions.GET_RECEIVED_SCORES: {
      const { paginationResult } = action.payload;
      return {
        ...state,
        scoresTable: {
          paginatorConfig: {
            perPage: +paginationResult["meta"]["per_page"],
            pages: paginationResult["meta"]["last_page"],
            index: +paginationResult["meta"]["current_page"],
          },
          paginationResult: paginationResult,
          isLoading: false,
        },
      };
    }

    case playersActions.GET_BILLS_STARTED: {
      return {
        ...state,
        billsTable: {
          paginatorConfig: state.billsTable.paginatorConfig,
          paginationResult: state.billsTable.paginationResult,
        },
        isLoading: true,
      };
    }

    case playersActions.GET_BILLS_SUCCESSFULLY: {
      const { paginationResult } = action.payload;

      return {
        ...state,
        billsTable: {
          paginatorConfig: {
            perPage: +paginationResult["meta"]["per_page"],
            pages: paginationResult["meta"]["last_page"],
            index: +paginationResult["meta"]["current_page"],
          },
          paginationResult: paginationResult,
        },
        isLoading: false,
      };
    }

    case playersActions.GET_REVIEWS_STARTED: {
      return {
        ...state,
        reviewsTable: {
          paginatorConfig: state.reviewsTable.paginatorConfig,
          paginationResult: state.reviewsTable.paginationResult,
        },
        isLoading: true,
      };
    }

    case playersActions.GET_PLAYER_REVIEWS_SUCCESSFULLY: {
      const { paginationResult } = action.payload;

      return {
        ...state,
        reviewsTable: {
          paginatorConfig: {
            perPage: +paginationResult["meta"]["per_page"],
            pages: paginationResult["meta"]["last_page"],
            index: +paginationResult["meta"]["current_page"],
          },
          paginationResult: paginationResult,
        },
        isLoading: false,
      };
    }

    case playersActions.PLAYERS_REVIEWS_SERVER_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case playersActions.CLEAR_PLAYER_REVIEWS: {
      return {
        ...state,
        playerReviews: null,
        serverErrors: null,
      };
    }

    default: {
      return state;
    }
  }
};
