
import {api} from '../../../../config/api';
import { HttpService } from '../../../../core/service/http.service';
import * as dateHelper from "../../../../core/helpers/date.helper";


export const VOUCHERS_PAGINATION_STARTED = 'VOUCHERS_PAGINATION_STARTED ';
export const VOUCHERS_PAGINATION_RESULT = 'VOUCHERS_PAGINATION_RESULT';
export const VOUCHERS_PAGINATION_ERROR_RESPONSE = 'PAGINATE_GAMES_ERROR_RESPONSE';
export const VOUCHERS_GET_BILLS_STARTED = 'GET_BILLS_STARTED';
export const VOUCHERS_GET_BILLS_SUCCESSFULLY = 'GET_BILLS_SUCCESSFULLY';
export const VOUCHERS_BILLS_ERROR_RESPONSE = 'BILLS_SERVER_ERROR_RESPONSE';
export const GET_VOUCHER = 'GET_VOUCHER';
export const CLEAR_VOUCHER_DETAILS = 'CLEAR_VOUCHER_DETAILS';
export const GET_VOUCHER_ERROR_RESPONSE = 'GET_VOUCHER_ERROR_RESPONSE';


export const EDIT_VOUCHER = 'EDIT_VOUCHER';
export const VOUCHER_SERVER_ERROR_RESPONSE = 'VOUCHER_SERVER_ERROR_RESPONSE';


export const GET_VOUCHER_LOGS_SUCCESSFULLY = 'GET_VOUCHER_LOGS_SUCCESSFULLY';
export const VOUCHER_LOGS_SERVER_ERROR_RESPONSE = "VOUCHER_LOGS_SERVER_ERROR_RESPONSE";
export const CLEAR_VOUCHER_LOGS = "CLEAR_VOUCHER_LOGS";


export const CHANGE_VOUCHER_SEARCH_ATTRIBUTES = 'CHANGE_VOUCHER_SEARCH_ATTRIBUTES';
export const CLEAR_VOUCHER_SEARCH_ATTRIBUTES = 'CLEAR_VOUCHER_SEARCH_ATTRIBUTES';


export function paginateVouchers(page, perPage,orderBy, orderDir,searchAttributes) {
    return (dispatch) => {
        dispatch({
            type: VOUCHERS_PAGINATION_STARTED,
            payload: {}
        });

        const url = api.VOUCHERS + '?page=' + page + '&per_page=' + perPage + '&orderBy=' + orderBy + '&orderDir=' + orderDir +
        '&started_at=' + (searchAttributes.fromDate ? dateHelper.format(searchAttributes.fromDate, 'YYYY-MM-DD 00:00:00') : '') +
        '&expired_at=' + (searchAttributes.toDate ? dateHelper.format(searchAttributes.toDate, 'YYYY-MM-DD 00:00:00') : '') +
        '&searchKey=' + searchAttributes.searchKey + '&new_user=' + (searchAttributes.new_user)+
        '&redemption_platform=' + (searchAttributes.redemption_platform);

        HttpService.prepareGetRequest(url).execute(response => {
            dispatch({
                type: VOUCHERS_PAGINATION_RESULT,
                payload: {
                    paginationResult: response
                }
            });
        }, error => dispatch({
            type: VOUCHERS_PAGINATION_ERROR_RESPONSE,
            payload: {
                serverErrors: error['response']['data']['data']
            }
        }));
    }
}


export function clearVoucherSearchAttributes() {
  return dispatch => dispatch({
    type: CLEAR_VOUCHER_SEARCH_ATTRIBUTES
  })
}


export function changeSearchVoucherAttributes(searchAttributes) {
  return dispatch => {
    dispatch({
      type: CHANGE_VOUCHER_SEARCH_ATTRIBUTES,
      payload: {
        searchAttributes: {...searchAttributes}
      }
    });
  };
}

export function getVoucherById(id) {
  const url = api.VOUCHERS + '/' + id;

  return (dispatch) => {
    HttpService.prepareGetRequest(url).execute(response => {
      dispatch({
        type: GET_VOUCHER,
        payload: {
          voucher: response['data']
        }
      })
    }, error => {
      dispatch({
      type: GET_VOUCHER_ERROR_RESPONSE,
      payload: {
          serverErrors: error['response']['data']['data']
      }
  })
})
  }
}

export function clearVoucherDetails() {
  return dispatch => dispatch({
    type: CLEAR_VOUCHER_DETAILS
  });
}

export function getVoucherBills(voucherId, page = 1 , perPage = 20) {
    return dispatch => {
      dispatch({
        type: VOUCHERS_GET_BILLS_STARTED
      });


      HttpService.prepareGetRequest(api.VOUCHER_BILLS.replace('{id}',voucherId) +'?page='+page +'&per_page='+perPage ).execute(response => {
        dispatch({
            type:VOUCHERS_GET_BILLS_SUCCESSFULLY,
            payload: {
              paginationResult: response
            }
          });

        },
        error => dispatch({
          type: VOUCHERS_BILLS_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']



          }
        })
      );
    }
  }

  export function getVoucherLogs(voucherId) {
    return dispatch => {


      HttpService.prepareGetRequest(api.VOUCHER_LOGS.replace('{id}', voucherId)).execute(response => {
          dispatch({
            type: GET_VOUCHER_LOGS_SUCCESSFULLY,
            payload: {
              logs: response['data']
            }
          });

        },
        error => dispatch({
          type: VOUCHER_LOGS_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['data']



          }
        })
      );
    }
  }





  export function createVoucher(voucher, successCb = null, failureCb = null) {
    return (dispatch) => {
      HttpService.preparePostRequest(api.VOUCHERS, voucher).execute(
        (response) => {
          if (successCb) {
            successCb(response);
          }
        },
        error => {
          dispatch({
            type: VOUCHER_SERVER_ERROR_RESPONSE,
            payload: {
              serverErrors: error['response']['data']['data']
            }
          });
          if (failureCb) {
            failureCb();
          }
        });
    };
  }

  export function editVoucher(id, voucher, successCb = null, failureCb = null) {
    return dispatch => {
      HttpService.preparePutRequest(`${api.VOUCHERS}/${id}`, voucher).execute(response => {
          dispatch({
            type: EDIT_VOUCHER,
            payload: {
              voucher: response['data']
            }
          });
          if (successCb) {
            successCb(response);
          }
        },
        error => {
          dispatch({
            type: VOUCHER_SERVER_ERROR_RESPONSE,
            payload: {
              serverErrors: error['response']['data']['data']
            }
          });
          if (failureCb) {
            failureCb();
          }
        });
    }
  }




  export function clearVoucherLogs(){
    return dispatch => dispatch({
      type: CLEAR_VOUCHER_LOGS
    });
  }

  export function deleteVoucher(id, cb) {
    return dispatch => HttpService.prepareDeleteRequest(api.VOUCHERS + '/' + id)
      .execute(() => cb())
  }



  export function restoreVoucher(id, cb) {
    return dispatch => HttpService.prepareGetRequest(api.VOUCHERS + '/' + id + '/restore')
      .execute(() => cb());
  }

