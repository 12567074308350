import * as smsActions from '../actions/sms.actions'
import {table} from "../../../players/config/config";

const initialState = {


  serverErrors: {},

  table: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: {data: []},
    isLoading: false
  }
};

export const smsReducer = function (state = initialState,action) {

  switch (action.type) {
    case smsActions.GET_SMS_LOGS_STARTED:{
      return {
        ...state,
        table: {
          paginatorConfig: state.table.paginatorConfig,
          paginationResult: state.table.paginationResult,
          isLoading: true
        }
      }
    }
    case smsActions.GET_SMS_LOGS_ERROR_RESPONSE:{
      return {
        ...state,
        ...action.payload,
        table: {
          ...state.table,
          isLoading: false
        }
      }
    }
    case smsActions.GET_SMS_LOGS_SUCCESSFULLY:{
      const {paginationResult} = action.payload;
      return {
        ...state,
        ...action.payload,
        table: {
          paginatorConfig: {
            perPage: +paginationResult['per_page'],
            pages: paginationResult['last_page'],
            index: +paginationResult['current_page']
          },
          paginationResult: paginationResult,
          isLoading: false
        }
      }
    }
    default:
      return state
  }
}
