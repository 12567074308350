import {api} from "../../../../../config/api";
import {HttpService} from "../../../../../core/service/http.service";

export const GET_STATES = 'GET_STATES';
export const GET_STATE = 'GET_STATE';
export const GET_STATES_STARTED = 'GET_STATES_STARTED';
export const STATES_SERVER_ERROR_RESPONSE = 'STATES_SERVER_ERROR_RESPONSE';
export const CLEAR_STATE_FORM = 'CLEAR_STATE_FORM';
export const STATE_DELETED = 'state Deleted Successfully';
export const STATE_RESTORED = 'state Restored Successfully';
export const CLEAR_STATES_DATA = 'CLEAR_STATES_DATA';

export function getStates(country_id) {
  return dispatch => {
    dispatch({
      type: GET_STATES_STARTED
    });
    HttpService.prepareGetRequest(api.STATES_IN_COUNTRY + '/' + country_id).execute(response => {
        dispatch({
          type: GET_STATES,
          payload: {
            states: response['data']
          }
        });
      },
      error => dispatch({
        type: STATES_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      }))
  };
}

export function getState(id) {
  return dispatch => HttpService.prepareGetRequest(api.STATES + '/' + id)
    .execute(response => {
      dispatch({
        type: GET_STATE,
        payload: {
          state: response['data']
        }
      });
    });
}


export function addState(body, countryId, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePostRequest(api.STATES, body).execute((response) => {
        dispatch(getStates(countryId));
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: STATES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      })
  };
}

export function editState(id, countryId, body, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePutRequest(api.STATES + '/' + id, body).execute((response) => {
        dispatch(getStates(countryId));
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: STATES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      })
  };
}

export function deleteState(id, countryId,successCb = null) {
  return dispatch => {
    HttpService.prepareDeleteRequest(api.STATES + '/' + id).execute(() => {
        dispatch({type: STATE_DELETED});
        dispatch(getStates(countryId));
        successCb();
      },
      error => dispatch({
        type: STATES_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      }))
  };
}

export function restoreState(id, countryId,successCb = null) {
  return dispatch => {
    HttpService.prepareGetRequest(api.STATES + '/' + id + '/restore').execute(() => {
        dispatch({type: STATE_RESTORED})
        dispatch(getStates(countryId));
        successCb();
      },
      error => dispatch({
        type: STATES_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      }))
  };
}

export function clearStateForm() {
  return dispatch => {
    dispatch({
      type: CLEAR_STATE_FORM
    });
  }
}

export function clearStatesData() {
  return dispatch => {
    dispatch({
      type: CLEAR_STATES_DATA
    });
  }
}

export function uploadStatePhoto(imageBase64 ,state ,form) {
  return dispatch => HttpService.preparePostRequest(`${api.UPLOAD_PHOTO_Thumbnail}?model=state&id=${state.id}`, imageBase64)
    .execute(
      (response) => {
        state.image = response["data"]["image_url"] ;
        form.setFieldValue('image', state.image);
      },
      error => {
        dispatch({
          type: STATES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']
          }
        });
      });
}
