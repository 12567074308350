import {HttpService} from "../../../../core/service/http.service";
import {api} from "../../../../config/api";

export const GET_SMS_LOGS_ERROR_RESPONSE = 'GET_SMS_LOGS_ERROR_RESPONSE';
export const GET_SMS_LOGS_SUCCESSFULLY = 'GET_SMS_LOGS_SUCCESSFULLY';
export const GET_SMS_LOGS_STARTED = 'GET_SMS_LOGS_STARTED';

export function paginateSmsLogs(page,perPage) {
  return (dispatch) => {
    dispatch({
      type: GET_SMS_LOGS_STARTED,
      payload: {}
    });
    const url = api.GET_SMS_LOGS + '?page=' + page + '&perPage=' + perPage;

    HttpService.prepareGetRequest(url).execute(response => {
        dispatch({
          type: GET_SMS_LOGS_SUCCESSFULLY,
          payload: {
            paginationResult: response
          }
        })
      },
      error => dispatch({
        type: GET_SMS_LOGS_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']['data']
        }
      }));
  };
}
