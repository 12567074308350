import React from "react";
import * as Yup from "yup";

export const table = {
  paginatorConfig: {
    perPage: 20,
    pages: 1
  },
  pageSizeOptions: [20, 50, 75, 100]
};

export const courseFormSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .min(3),
  course_type_id: Yup.number().required(),
  state_id: Yup.number().required(),
  street: Yup.string().nullable().min(5),
  website: Yup.string().nullable().url(),
  note: Yup.string().nullable()
});

export const manageCoursesFilterSchema = Yup.object().shape({
  searchKey: Yup.string()
    .min(1),
});
