import React from "react";
import * as Yup from "yup";

export const table = {
  paginatorConfig: {
    perPage: 20,
    pages: 1
  },
  pageSizeOptions: [20, 50, 75, 100]
};

export const addUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .required(),
  last_name: Yup.string()
    .required(),
  phone_number: Yup.string()
    .required(),
  email: Yup.string()
    .required()
    .email(),
  password: Yup.string()
    .required()
    .min(6),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password')])
    .required()
    .min(6),
  role: Yup.object()
    .required(),

});

export const addUserWithBankInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .required(),
  last_name: Yup.string()
    .required(),
  phone_number: Yup.string()
    .required(),
  email: Yup.string()
    .required()
    .email(),
  password: Yup.string()
    .required()
    .min(6),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password')])
    .required()
    .min(6),
  role: Yup.object()
    .required(),
   bank_info:Yup.object().shape({
     code: Yup.string()
       .required(),
     bank_name: Yup.string()
       .required(),
     acc_no: Yup.string()
       .required(),
     name: Yup.string()
       .required(),
     id_no: Yup.string()
       .required(),
   })
});

export const editUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .required(),
  last_name: Yup.string()
    .required(),
  phone_number: Yup.string()
    .required(),
  email: Yup.string()
    .required()
    .email(),
  role: Yup.object()
    .required(),
});

export const editUserWithBankInfoSchema = Yup.object().shape({
  first_name: Yup.string()
    .required(),
  last_name: Yup.string()
    .required(),
  phone_number: Yup.string()
    .required(),
  email: Yup.string()
    .required()
    .email(),
  role: Yup.object()
    .required(),
  bank_info:Yup.object().shape({
    code: Yup.string()
      .required(),
    bank_name: Yup.string()
      .required(),
    acc_no: Yup.string()
      .required(),
    name: Yup.string()
      .required(),
    id_no: Yup.string()
      .required(),
  })
});

export const bankAccountStatus = {
  'pending' : <span style={{color:'#eec533'}}>Pending</span>,
  'verified' : <span style={{color:'#12ee00'}}>Verified</span>,
  'rejected' : <span style={{color:'#ee1108'}}>Rejected</span>
};

export const manageUserSchema = Yup.object().shape({
  searchKey: Yup.string()
      .min(2),
});


