
export const client = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  GAMES: '/games',
  PLAYERS: '/players',
  PROFILE: '/profile',
  COURSES: '/courses',
  PACKAGES: '/packages',
  SLUGS: '/slugs',
  USERS: '/users',
  BILLS: '/bills',
  VOUCHERS:'/vouchers',
  FAQ:'/faq',
  FAILED_JOBS:"/failed-jobs"
};
