import * as LoginActions from '../actions/login.actions';

const initialState = {
    serverErrors: {}
};

export const loginReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case LoginActions.LOGIN_ERROR_RESPONSE:
        {
            return {
                ...initialState,
                ...action.payload
            };
        }
        default:
        {
            return state
        }
    }
};
