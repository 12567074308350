import {api} from "../../../../config/api";
import {HttpService} from "../../../../core/service/http.service";
import {TokenService} from "../../../../core/service/token-service";
import {store} from "../../../../shared/store";
import * as firebase from "firebase";
import {Notification} from '../../../../core/models/Message';
import moment from "moment";

export const SET_USER_DATA = 'SET_USER_DATA';
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';

/**
 * Set user data in user state
 * @param user
 * @returns {function(*): *}
 */
export function setUserData(user) {
  return dispatch => dispatch({
    type: SET_USER_DATA,
    payload: {
      user: user
    }
  });
}

/**
 * Remove user data from user state
 * @returns {function(*): *}
 */
export function removeUserData() {
  TokenService.logout();
  return dispatch => dispatch({
    type: REMOVE_USER_DATA
  });
}

/**
 * Request user details
 * @returns {function(*): void}
 */
export function mySelf() {
  return dispatch => HttpService.prepareGetRequest(api.MYSELF, {})
    .execute(response => dispatch(setUserData(response['data'])));
}


export function getAdminNotifications(adminId) {
  return (dispatch) => {
    const adminNotificationsRef = firebase.database().ref('adminNotifications');
    adminNotificationsRef.child(adminId+ '/games').on('value',function(snapshot)  {
      const notifications = snapshot.val();
        dispatch({
          type: GET_USER_NOTIFICATIONS,
          payload: {
            notifications: (notifications) ? notifications : {} 
          }
        })
    });
  };
}


export function updateAdminNotification(adminId, gameId) { // this is called to remove the clicked notifications. 
      return () => {
        const path = 'adminNotifications/' + adminId + '/games/' + gameId;
        firebase.database().ref(path).once('value').then(snapshot => {
          const gameNotification = snapshot.val();
          if (gameNotification) {
            const updates = {}; 
            updates[path] = {};
            firebase.database().ref().update(updates)
              .then(() => {})
              .catch(r => console.log('Error updating game adminNotifications.', r));
          }
        });
    };
}
 // called to remove clicked notifications for all other associated admins for the current logged in admin. 
export  function updateOtherAdminsNotification(adminId, gameId) {
  return () => {
  const adminsPath = 'adminNotifications/' + adminId + '/admin_ids';
  firebase.database().ref(adminsPath).once('value').then(snapshot => {
    const adminsId  = snapshot.val(); 
    if (adminsId) { 
      Object.keys(adminsId).forEach(key => {
        const path = 'adminNotifications/' + key + '/games/' + gameId;
        firebase.database().ref(path).once('value').then(snapshot => {
          const gameNotification = snapshot.val();
          if (gameNotification) {
            const updates = {};
            updates[path] = {};
            firebase.database().ref().update(updates)
              .then(() => {})
              .catch(r => console.log('Error updating game adminNotifications.', r));
          }
        });
      })
    }
  });
  }
}

// this is called to add a new remind me notification for the logged in admin
export function addRemindMeNotification(adminId, gameId,adminName='Admin',gameTitle='Game') {
      return () => {
        const path = 'adminNotifications/' + adminId + '/games/' + gameId;
        Notification.message = `${adminName} has requested to be reminded of ${gameTitle}`;
        Notification.time = moment.utc(moment.now()).format('YYYY-MM-DD HH:mm:ss');

        // has to check the current total value 
        firebase.database().ref(path).once('value').then(snapshot => {
          const notification = snapshot.val();
          Notification.total = notification ? (notification.total + 1) : 1;
           firebase.database().ref(path).set(Notification).then(()=> {})
        .catch(r => console.log('Error creating game adminNotification.', r));
        })

       
        
    };
}

// called to add the new remind me to other associated admins
export function addRemindMeNotificationToOtherAdmins(adminId, gameId,adminName='Admin',gameTitle='Game') {
  return () => {
  const adminsPath = 'adminNotifications/' + adminId + '/admin_ids';
  firebase.database().ref(adminsPath).once('value').then(snapshot => {
    const adminsId  = snapshot.val(); 
    Notification.message = `${adminName} has requested to be reminded of ${gameTitle}`;
    Notification.time = moment.utc(moment.now()).format('YYYY-MM-DD HH:mm:ss');

    if (adminsId) { 
      Object.keys(adminsId).forEach(key => {
        const path = 'adminNotifications/' + key + '/games/' + gameId;

        // need to check counter for each game for each admin before adding the remind me notification! 
         firebase.database().ref(path).once('value').then(snapshot => {
         const notification = snapshot.val();
         Notification.total = notification ? (notification.total + 1) : 1;
         firebase.database().ref(path).set(Notification)
         .then(()=> {})
         .catch(r => console.log('Error creating game adminNotification.', r))

        })

      })
      
    }
   
  });
 }

}



export function setAllNotificationsAsRead(adminId) {
  return () => {
    const path = 'adminNotifications/' + adminId + '/games';
    firebase.database().ref(path).once('value').then(snapshot => {
      const gamesNotifications = snapshot.val();
      if (gamesNotifications) {
        const updates = {};
        // Object.keys(gamesNotifications).forEach(key =>{
        //   updates[path+'/'+key] = {...gamesNotifications[key],seen: true, total: 0};
        //   updates[path+'/'+key] = {};
        // })
        updates[path] = {}
        firebase.database().ref().update(updates)
          .then(() => {})
          .catch(r => console.log('Error updating game adminNotifications.', r));
      }
    });
  };
}


