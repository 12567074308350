export const config = {
  googleMap: {
    url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAELGTi7WVM3mdyyQw8CU09iRBQ3FfGpj8&v=3.exp&libraries=geometry,drawing,places',
    defaultZoom: 8,
    draggable: true,
    // Default of Malaysia
    position: {lat: 4.210484, lng: 101.975769},
    address:'Gua, Pahang, Malaysia'
  },
  firebase: JSON.parse(process.env.REACT_APP_FIREBASE),
};
