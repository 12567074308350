import {api} from "../../../../config/api";
import {HttpService} from "../../../../core/service/http.service";

export const REQUEST_RESET_ERROR = 'REQUEST_RESET_ERROR';

/**
 * LogIn user http request
 */
export function requestResetPassword(email, successCb = null, failureCb = null) {
  return dispatch => HttpService.preparePostRequest(api.RESET_PASSWORD, email)
    .execute(
      response => {
        if (successCb) {
          successCb();
        }
      },
      error => {
        dispatch({
          type: REQUEST_RESET_ERROR,
          payload: {
            serverErrors: error
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
}
