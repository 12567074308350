import { api } from "../../../../../config/api";
import { HttpService } from "../../../../../core/service/http.service";
import * as dateHelper from "../../../../../core/helpers/date.helper";
import {
  PAYMENT_SERVER_ERROR_RESPONSE
} from "../../../../games/store/actions/game.actions";

export const GET_BILLS = 'GET_BILLS';
export const GET_BILLS_STARTED = 'GET_BILLS_STARTED';
export const BILLS_PAGINATION_RESULT = 'BILLS_PAGINATION_RESULT';
export const BILLS_SERVER_ERROR_RESPONSE = 'BILLS_SERVER_ERROR_RESPONSE';
export const CHANGE_SEARCH_BILL_ATTRIBUTES = 'CHANGE_SEARCH_BILL_ATTRIBUTES';
export const GET_CHANNELS = 'GET_CHANNELS';
export const CHANNELS_SERVER_ERROR_RESPONSE = 'CHANNELS_SERVER_ERROR_RESPONSE';
export const REFUND_BILL_SUCCESSFULLY = 'REFUND_BILL_SUCCESSFULLY';

export function paginateBills(page, perPage, collectionId = null, searchAttributes, finallyCb) {
  return dispatch => {
    dispatch({
      type: GET_BILLS_STARTED
    });
    // Set default 'from_paid_at' to the first day of the current month if not provided
    if (searchAttributes && !searchAttributes.from_paid_at) {
      const now = new Date();
      searchAttributes.from_paid_at = new Date(now.getFullYear(), now.getMonth(), 1);
    }

    HttpService.prepareGetRequest(api.BILLS + '' +
      '?page=' + page + '&per_page=' + perPage + (collectionId ? '&collection_id=' + collectionId : '')
      + (searchAttributes ? (searchAttributes.course_id ? '&course_id=' + searchAttributes.course_id : '')
        + (searchAttributes.sys_user_id ? '&sys_user_id=' + searchAttributes.sys_user_id : '')
        + (searchAttributes.channel ? '&channel=' + searchAttributes['channel'] : '')
        + (searchAttributes.from_paid_at ? '&from_paid_at=' + dateHelper.format(searchAttributes.from_paid_at, 'YYYY-MM-DD 00:00:00') : '')
        + (searchAttributes.to_paid_at ? '&to_paid_at=' + dateHelper.format(searchAttributes.to_paid_at, 'YYYY-MM-DD 00:00:00') : '')
        + (searchAttributes.from_game_at ? '&from_game_at=' + dateHelper.format(searchAttributes.from_game_at, 'YYYY-MM-DD 00:00:00') : '')
        + (searchAttributes.to_game_at ? '&to_game_at=' + dateHelper.format(searchAttributes.to_game_at, 'YYYY-MM-DD 00:00:00') : '')
        + (searchAttributes['credit_used'] ? ('&credit_used=' + true) : ('&credit_used=' + false))
        + (searchAttributes['voucher_used'] ? '&voucher_used=' + true : '')
        + (searchAttributes['voucher_not_used'] ? '&voucher_not_used=' + true : '')
        + (searchAttributes['currency'] ? '&currency=' + searchAttributes['currency'] : '')
        + (searchAttributes['search_key'] ? '&search_key=' + searchAttributes['search_key'] : '') : '')
    ).execute(response => {
      dispatch({
        type: BILLS_PAGINATION_RESULT,
        payload: {
          paginationResult: response
        }

      });
    },
      error => dispatch({
        type: BILLS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      })
      , finallyCb
    )
  };
}

export function changeSearchBillsAttributes(searchAttributes, perPage = 20, finallyCb) {
  return dispatch => {
    dispatch({
      type: CHANGE_SEARCH_BILL_ATTRIBUTES,
      payload: {
        searchAttributes: { ...searchAttributes }
      }
    });
    dispatch(paginateBills(1, perPage, null, searchAttributes, finallyCb));
  };
}


export function getChannels() {
  return dispatch => {
    dispatch({
      type: GET_CHANNELS
    });
    HttpService.prepareGetRequest(api.CHANNELS).execute(response => {
      dispatch({
        type: GET_CHANNELS,
        payload: {
          channels: response['data']
        }
      });
    },
      error => dispatch({
        type: CHANNELS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      }))
  };
}

export function refundBill(params, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePostRequest(api.REFUND_BILL, params).execute(
      (response) => {
        dispatch({
          type: REFUND_BILL_SUCCESSFULLY,
          payload: {
            game: response['data'],
          }
        });
        if (successCb) {
          successCb(response['data']);
        }
      },
      error => {
        dispatch({
          type: PAYMENT_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
  };
}
