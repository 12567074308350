import * as sharedActions from '../actions/shared.actions'

const initialState = {
  countries: ''
};


export const sharedReducer = (state = initialState, action) => {
  switch (action.type) {
    case sharedActions.COUNTRIES:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
