import * as PackageActions from '../actions/package.actions';
import { table } from '../../config/config';

const initialState = {
  packages: null,
  iPackage: null,
  serverErrors: {},
  searchAttributes: {
    country_id: '',
    state_id: '',
    searchKey: '',
    is_active: false,
    is_deleted: false,
  },

  table: {
    paginatorConfig: table.paginatorConfig,
    data: [],
    isLoading: false,
  },
  courseReviewsTable: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: { data: [] },
    isLoading: false,
  },
};

export const packageReducer = function (state = initialState, action) {
  switch (action.type) {
    case PackageActions.GET_PACKAGE:
      return {
        ...state,
        packages: action.payload.packages,
      };
    // case PackageActions.GET_PACKAGE:
    case PackageActions.EDIT_PACKAGE:
    case PackageActions.CREATE_PACKAGE:
    case PackageActions.PACKAGES_PAGINATION_RESULT: {
      const { paginationResult } = action.payload;
      return {
        ...state,
        ...action.payload,
        table: {
          paginatorConfig: {
            perPage: (paginationResult != undefined ? +paginationResult['per_page'] : 20),
            pages: (paginationResult != undefined? paginationResult['last_page'] : 1),
            index:(paginationResult != undefined ? paginationResult['current_page']: 1)
          },
          paginationResult: paginationResult,
          isLoading: false,
        },
      };
    }
    case PackageActions.PAGINATION_STARTED: {
      return {
        ...state,
        table: {
          paginatorConfig: state.table.paginatorConfig,
          paginationResult: state.table.paginationResult,
          isLoading: true,
        },
      };
    }
    case PackageActions.PAGINATE_PACKAGES_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload,
        table: {
          ...state.table,
          isLoading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
