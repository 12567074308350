import {HttpService} from "../../../core/service/http.service";
import {api} from "../../../config/api";

export const COUNTRIES = 'COUNTRIES';

export function getCountries(){
 return dispatch => HttpService.prepareGetRequest(api.COUNTRIES).execute(response => {
   dispatch({
     type: COUNTRIES,
     payload: {
       countries: response['data']
     }
   })
 })
}


