import {HttpService} from "../../../../core/service/http.service";
import {api} from "../../../../config/api";
import * as dateHelper from "../../../../core/helpers/date.helper";

export const PAGINATION_STARTED = 'PAGINATION_STARTED';
export const PAGINATE_COURSES_ERROR_RESPONSE = 'PAGINATE_COURSES_ERROR_RESPONSE';
export const COURSES_SERVER_ERROR_RESPONSE = 'COURSES_SERVER_ERROR_RESPONSE';
export const COURSES_PAGINATION_RESULT = 'COURSES_PAGINATION_RESULT';
export const CHANGE_SEARCH_COURSE_ATTRIBUTES = 'CHANGE_SEARCH_COURSE_ATTRIBUTES';
export const CLEAR_COURSE_FORM_DATA = 'CLEAR_COURSE_FORM_DATA';
export const CLEAR_COURSE_SEARCH_ATTRIBUTES = 'CLEAR_COURSE_SEARCH_ATTRIBUTES';
export const GET_COURSE = 'GET_COURSE';
export const EDIT_COURSE = 'EDIT_COURSE';
export const CREATE_COURSE = 'CREATE_COURSE';
export const GET_COURSE_REVIEWS_STARTED = "GET_COURSE_REVIEWS_STARTED";
export const GET_COURSE_REVIEWS_SUCCESSFULLY = "GET_COURSE_REVIEWS_SUCCESSFULLY";
export const COURSE_REVIEWS_SERVER_ERROR_RESPONSE = "COURSE_REVIEWS_SERVER_ERROR_RESPONSE";


export function paginateCourses(page,perPage,orderBy,orderDir,searchAttributes) {
  return (dispatch) =>{
    dispatch({
      type: PAGINATION_STARTED,
      payload: {}
    });
    const url = api.COURSES + '?page=' + page + '&per_page=' + perPage + '&orderBy=' + orderBy + '&orderDir=' + orderDir
      + '&country_id=' + searchAttributes.country_id + '&state_id=' + searchAttributes.state_id +
      '&search_key=' + searchAttributes.searchKey+'&is_standard=' + searchAttributes.is_standard +
      '&has_driving=' + searchAttributes.has_driving_range +
      '&from_date=' + (searchAttributes.fromDate ? dateHelper.format(searchAttributes.fromDate, 'YYYY-MM-DD 00:00:00') : '') +
      '&to_date=' + (searchAttributes.toDate ? dateHelper.format(searchAttributes.toDate, 'YYYY-MM-DD 00:00:00') : '') +
      '&is_active=' + searchAttributes.is_active+'&is_deleted=' + searchAttributes.is_deleted
      +'&is_featured=' + searchAttributes.is_featured;

    HttpService.prepareGetRequest(url).execute(response => {
        dispatch({
          type: COURSES_PAGINATION_RESULT,
          payload: {
            paginationResult: response
          }
        })
      },
      error => dispatch({
        type: PAGINATE_COURSES_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']['data']
        }
      }));
  }
}

export function createCourse(course, successCb = null, failureCb = null) {
  return (dispatch) => {
    HttpService.preparePostRequest(api.COURSES, course).execute(
      (response) => {
        dispatch({
          type: CREATE_COURSE,
          payload: {
            serverErrors: {}
          }
        });
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: COURSES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      }
    );
  };
}

export function changeSearchCourseAttributes(searchAttributes) {
  return dispatch => {
    dispatch({
      type: CHANGE_SEARCH_COURSE_ATTRIBUTES,
      payload: {
        searchAttributes: searchAttributes,
      }
    });
  };
}

export function getCourseById(id) {
  return dispatch => {
    HttpService.prepareGetRequest(`${api.COURSES}/${id}`).execute(response => {
      dispatch({
        type: GET_COURSE,
        payload: {
          course: response['data']
        }
      })
    })
  }
}

export function editCourse(id, course, successCb = null, failureCb = null) {
  return dispatch => {
    HttpService.preparePutRequest(`${api.COURSES}/${id}`, course).execute(response => {
        dispatch({
          type: EDIT_COURSE,
          payload: {
            course: response['data'],
            serverErrors: {}
          }
        });
        if (successCb) {
          successCb(response);
        }
      },
      error => {
        dispatch({
          type: COURSES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data']
          }
        });
        if (failureCb) {
          failureCb();
        }
      });
  }
}

export function deleteCourse(id,successCb = null) {
  return dispatch => HttpService.prepareDeleteRequest(api.COURSES + '/' + id).execute(() => {successCb();})
}

export function restoreCourse(id,successCb = null) {
  return dispatch => HttpService.prepareGetRequest(api.COURSES + '/' + id + '/restore')
    .execute(() => {successCb(); })
}

export function clearCourseFormData() {
  return dispatch => dispatch({
    type: CLEAR_COURSE_FORM_DATA
  })
}

export function clearCourseSearchAttributes() {
  return dispatch => dispatch({
    type: CLEAR_COURSE_SEARCH_ATTRIBUTES
  });
}

export function getCourseReviews(courseId, page = 1 , perPage = 20) {
  return dispatch => {
    dispatch({
      type: GET_COURSE_REVIEWS_STARTED
    });

    HttpService.prepareGetRequest(api.COURSE_REVIEWS.replace('{id}',courseId) +'?page='+page +'&per_page='+perPage ).execute(response => {
        dispatch({
          type:GET_COURSE_REVIEWS_SUCCESSFULLY,
          payload: {
            paginationResult: response
          }
        });

      },
      error => dispatch({
        type: COURSE_REVIEWS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      })
    );
  }
}

export function uploadCoursePhoto(imageBase64 ,course, form ) {
  return dispatch => HttpService.preparePostRequest(`${api.UPLOAD_PHOTO_Thumbnail}?model=course&id=${course.id}`, imageBase64)
    .execute(
      (response) => {
        course.image = course.image === null ? response["data"]["image_url"] : course.image + "|" + response["data"]["image_url"];
        form.setFieldValue('image', course.image);
        console.log(course.image.split("|"))
      },
      error => {
        dispatch({
          type: COURSES_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']
          }
        });
      });
}
