import {HttpService} from "../../../../core/service/http.service";
import {api} from "../../../../config/api";

export const GET_DASHBOARD_DETAILS = 'GET_DASHBOARD_DETAILS';
export const GET_DASHBOARD_DETAILS_ERROR = 'GET_DASHBOARD_DETAILS_ERROR';
export const GET_PLAYERS_PER_PROVIDERS = 'GET_PLAYERS_PER_PROVIDERS';
export const GET_PLAYERS_PER_PROVIDERS_ERROR = 'GET_PLAYERS_PER_PROVIDERS_ERROR';
export const GET_CREATORS_JOINERS = 'GET_CREATORS_JOINERS';
export const GET_CREATORS_JOINERS_ERROR = 'GET_CREATORS_JOINERS_ERROR';
export const GET_PLAYERS_PER_COUNTRY = 'GET_PLAYERS_PER_COUNTRY';
export const GET_PLAYERS_PER_COUNTRY_ERROR = 'GET_PLAYERS_PER_COUNTRY_ERROR';
export const GET_GAME_STATUS_PER_COUNTRY = 'GET_GAME_STATUS_PER_COUNTRY';
export const GET_GAME_STATUS_PER_COUNTRY_ERROR = 'GET_GAME_STATUS_PER_COUNTRY_ERROR';

export const GET_GAME_TYPE_PER_COUNTRY = 'GET_GAME_TYPE_PER_COUNTRY';
export const GET_GAME_TYPE_PER_COUNTRY_ERROR = 'GET_GAME_TYPE_PER_COUNTRY_ERROR';

export const GET_GAME_ROUNDS_PER_COUNTRY = 'GET_GAME_ROUNDS_PER_COUNTRY';
export const GET_GAME_ROUNDS_PER_COUNTRY_ERROR = 'GET_GAME_ROUNDS_PER_COUNTRY_ERROR';

export const GET_PAYMENT_STATISTICS = 'GET_PAYMENT_STATISTICS';
export const GET_PAYMENT_STATISTICS_ERROR = 'GET_PAYMENT_STATISTICS_ERROR';

export const GET_PAYOUT_STATISTICS = 'GET_PAYOUT_STATISTICS';
export const GET_PAYOUT_STATISTICS_ERROR = 'GET_PAYOUT_STATISTICS_ERROR';


export const GET_TOTAL_PAYMENT = 'GET_TOTAL_PAYMENT';
export const GET_TOTAL_PAYMENT_ERROR = 'GET_TOTAL_PAYMENT_ERROR';

export const GET_VALUES_PAYMENT = 'GET_VALUES_PAYMENT';
export const GET_VALUES_PAYMENT_ERROR = 'GET_VALUES_PAYMENT_ERROR';

export const GET_REFERRALS = 'GET_REFERRALS';
export const GET_REFERRALS_ERROR = 'GET_REFERRALS_ERROR';
export const  GET_PAID_REFERRALS = 'GET_PAID_REFERRALS';
export const GET_PAID_REFERRALS_ERROR = 'GET_PAID_REFERRALS_ERROR';

export function getDashboardDetails(fromDate, toDate) {

  return (dispatch) => {
    HttpService.prepareGetRequest(api.DASHBOARD + '?fromDate=' + fromDate + '&toDate=' + toDate).execute(response => {
      dispatch({
        type: GET_DASHBOARD_DETAILS,
        payload: {
          dashboardDetails: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_DASHBOARD_DETAILS_ERROR,
        payload: error['message']
      })
    })
  }

}


export function getPlayersPerProviders(fromDate, toDate) {

  return (dispatch) => {
    HttpService.prepareGetRequest(api.PLAYERS_PER_PROVIDERS + '?fromDate=' + fromDate + '&toDate=' + toDate).execute(response => {
      dispatch({
        type: GET_PLAYERS_PER_PROVIDERS,
        payload: {
          dashboardDetails: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_PLAYERS_PER_PROVIDERS_ERROR,
        payload: error['message']
      })
    })
  }

}


export function getCreatorsAndJoiners(fromDate, toDate) {

  return (dispatch) => {
    HttpService.prepareGetRequest(api.CREATORS_JOINERS + '?fromDate=' + fromDate + '&toDate=' + toDate).execute(response => {
      dispatch({
        type: GET_CREATORS_JOINERS,
        payload: {
          dashboardDetails: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_CREATORS_JOINERS_ERROR,
        payload: error['message']
      })
    })
  }

}

export function getPlayersPerCountry(fromDate, toDate) {

  return (dispatch) => {
    HttpService.prepareGetRequest(api.PLAYERS_PER_COUNTRY + '?fromDate=' + fromDate + '&toDate=' + toDate).execute(response => {
      dispatch({
        type: GET_PLAYERS_PER_COUNTRY,
        payload: {
          dashboardDetails: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_CREATORS_JOINERS_ERROR,
        payload: error['message']
      })
    })
  }
}

export function getGameStatusPerCountry(country, from, to) {
  return (dispatch) => {
    const url = api.GAME_STATUS_PER_COUNTRY + `?countryId=${country}&fromDate=${from}&toDate=${to}`;
    HttpService.prepareGetRequest(url).execute(response => {
      dispatch({
        type: GET_GAME_STATUS_PER_COUNTRY,
        payload: {
          gamesStatusPerCountry: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_GAME_STATUS_PER_COUNTRY_ERROR,
        payload: error['message']
      })
    })
  }
}

export function getGameTypePerCountry(country, from, to) {
  return (dispatch) => {
    const url = api.GAME_TYPE_PER_COUNTRY + `?countryId=${country}&fromDate=${from}&toDate=${to}`;
    HttpService.prepareGetRequest(url).execute(response => {
      dispatch({
        type: GET_GAME_TYPE_PER_COUNTRY,
        payload: {
          gamesTypePerCountry: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_GAME_TYPE_PER_COUNTRY_ERROR,
        payload: error['message']
      })
    })
  }
}

export function getGameRoundsPerCountry(country,from,to) {
  return (dispatch) => {
    const url = api.GAME_ROUNDS_PER_COUNTRY + `?countryId=${country}&fromDate=${from}&toDate=${to}`;
    HttpService.prepareGetRequest(url).execute(response => {
      dispatch({
        type: GET_GAME_ROUNDS_PER_COUNTRY,
        payload: {
          gameRoundsPerCountry: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_GAME_ROUNDS_PER_COUNTRY_ERROR,
        payload: error['message']
      })
    })
  }
}


export function getPaymentStatistics(fromDate, toDate, courseId) {

  return (dispatch) => {
    HttpService.prepareGetRequest(api.PAYMENT + '?fromDate=' + fromDate + '&toDate=' + toDate + (courseId ? '&courseId=' + courseId : '')).execute(response => {
      dispatch({
        type: GET_PAYMENT_STATISTICS,
        payload: {
          paymentStatistics: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_PAYMENT_STATISTICS_ERROR,
        payload: error['message']
      })
    })
  }

}

export function getPayoutStatistics(fromDate, toDate) {

  return (dispatch) => {
    HttpService.prepareGetRequest(api.PAYOUT + '?fromDate=' + fromDate + '&toDate=' + toDate ).execute(response => {
      dispatch({
        type: GET_PAYOUT_STATISTICS,
        payload: {
          payoutStatistics: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_PAYOUT_STATISTICS_ERROR,
        payload: error['message']
      })
    })
  }

}

export function getReferrals(from,to) {
  return (dispatch) => {
    const url = api.REFERRALS_REPORT + `?fromDate=${from}&toDate=${to}`;
    HttpService.prepareGetRequest(url).execute(response => {
      dispatch({
        type: GET_REFERRALS,
        payload: {
          referrals: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_REFERRALS_ERROR,
        payload: error['message']
      })
    })
  }
}


export function getPaidReferrals(from,to) {
  return (dispatch) => {
    const url = api.PAID_REFERRALS_REPORT + `?fromDate=${from}&toDate=${to}`;
    HttpService.prepareGetRequest(url).execute(response => {
      dispatch({
        type: GET_PAID_REFERRALS,
        payload: {
          paidReferrals: response['data']
        }
      })
    }, (error) => {
      dispatch({
        type: GET_PAID_REFERRALS_ERROR,
        payload: error['message']
      })
    })
  }
}
