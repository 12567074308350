import {table} from "../../config/config";
import { faqsActions } from "../actions";
import * as faqActions from '../actions/faq.actions'

const initialState = {
    faq: null,
    table: {
        paginatorConfig: table.paginatorConfig,
        paginationResult: {data: []},
        isLoading: false
      },
      serverErrors: {},
      searchAttributes: {
        fromDate: null,
        toDate: null,
        searchKey: '',
        new_user: 0
      },
      billsTable: {
        paginatorConfig: table.paginatorConfig,
        paginationResult: {data: []},
        isLoading: false
      },
      logs: []



};


export const faqReducer = function ( state = initialState, action) {
    switch(action.type) {

        case faqActions.FAQ_PAGINATION_STARTED: {
            return {
              ...state,
              table: {
                paginatorConfig: state.table.paginatorConfig,
                paginationResult: state.table.paginationResult,
                isLoading: true
              }
            }
          }

       case faqActions.FAQS_PAGINATION_RESULT: {
           const {paginationResult} = action.payload;
            return {
                ...state,
                ...action.payload,
                table: {
                    paginatorConfig: {
                        perPage: +paginationResult['meta']['per_page'],
                        pages: paginationResult['meta']['last_page'],
                        index: paginationResult['meta']['current_page']
                    },
                    paginationResult: paginationResult,
                    isLoading: false

                }
            }
       }


       case faqActions.FAQS_PAGINATION_ERROR_RESPONSE: {
        return {
          ...state,
          ...action.payload,
          table: {
            ...state.table,
            isLoading: false
          }
        }
      }


      case faqActions.FAQS_GET_BILLS_STARTED: {
        return {
          ...state,
          billsTable: {
            paginatorConfig: state.billsTable.paginatorConfig,
            paginationResult: state.billsTable.paginationResult,
            isLoading: true
          }

        }
      }

      case faqActions.FAQS_GET_BILLS_SUCCESSFULLY: {
        const {paginationResult} = action.payload;

        return {
          ...state,
          billsTable: {
            paginatorConfig: {
              perPage: +paginationResult['meta']['per_page'],
              pages: paginationResult['meta']['last_page'],
              index: +paginationResult['meta']['current_page']
            },
            paginationResult: paginationResult,
            isLoading: false
          }



        }
      }

      case faqActions.FAQS_BILLS_ERROR_RESPONSE: {
        return {
          ...state,
          ...action.payload,
          billsTable: {
            ...state.billsTable,
            isLoading: false
          }
        }
      }

      case faqActions.GET_FAQ: {
        return {
          ...state,
          ...action.payload
        }
      }

      case faqActions.GET_FAQ_ERROR_RESPONSE: {
        return {
          ...state,
          ...action.payload
        }
      }

      case faqActions.CLEAR_FAQ_DETAILS: {
        return {
          ...state,
          faq: null
        }
      }



      case faqActions.FAQ_SERVER_ERROR_RESPONSE: {
        return {
          ...state,
          ...action.payload,
          isLoading: false
        };
      }
      case faqActions.EDIT_FAQ: {
        return {
          ...state,
          ...action.payload,
          serverErrors: {}
        }
      }




      case faqActions.GET_FAQ_LOGS_SUCCESSFULLY: {
        return {
          ...state,
          ...action.payload,
        }
      }

      case faqActions.CLEAR_FAQ_LOGS: {
        return {
          ...state,
          logs: []
        }
      }

      case faqActions.FAQ_LOGS_SERVER_ERROR_RESPONSE: {
        return {
          ...state,
          serverErrors: action.payload
        }
      }

      case faqActions.CLEAR_FAQ_SEARCH_ATTRIBUTES: {
        return {
          ...state,
          searchAttributes: {
            ...initialState.searchAttributes
          }
        }
      }
      case faqActions.CHANGE_FAQ_SEARCH_ATTRIBUTES: {
        return {
          ...state,
          ...action.payload
        }
      }


      default: {
        return state;
      }

    }

}
