import * as rolesActions from '../actions/roles.actions';

const initialState = {
  // If edit mode
  role: null,
  roles: [],
  permissions: [],
  serverErrors: {},
  isLoading: false
};

export const rolesReducer = function (state = initialState, action) {

  switch (action.type) {
    case rolesActions.GET_PERMISSIONS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case rolesActions.GET_ROLES: {
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    }
    case rolesActions.GET_ROLE: {
      return {
        ...state,
        ...action.payload
      }
    }
    case rolesActions.ROLES_SERVER_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case rolesActions.CLEAR_ROLE_FORM: {
      return {
        ...state,
        role: null,
        serverErrors: {}
      };
    }
    case rolesActions.GET_ROLES_STARTED: {
      return {
        ...state,
        isLoading: true

      }
    }
    default: {
      return state;
    }
  }
};
