import * as statesActions from '../actions/states.action';

const initialState = {
  // If edit mode
  state: null,
  states: [],
  serverErrors: {},
  isLoading: false
};

export  const statesReducer = function (state = initialState, action) {
  switch (action.type) {
    case statesActions.GET_STATES_STARTED:{
      return {
        ...state,
        isLoading: true
      }
    }
    case  statesActions.GET_STATES:{
      return{
        ...state,
        states: action.payload.states,
        isLoading:false
      }
    }

    case statesActions.GET_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    case  statesActions.STATES_SERVER_ERROR_RESPONSE:{
      return{
        ...state,
        serverErrors: action.payload.serverErrors,
        isLoading:false
      }
    }
    case statesActions.CLEAR_STATE_FORM: {
      return {
        ...state,
        state: null,
        serverErrors: {}
      };
    }
    case statesActions.CLEAR_STATES_DATA: {
      return {
        ...state,
        state: null,
        serverErrors: {}
      };
    }
    default:{
      return state;
    }
  };

}
