import {api} from "../../../../../config/api";
import {HttpService} from "../../../../../core/service/http.service";
import {GET_COUNTRY} from "../../../../places/countries/store/actions/countries.action";
import * as dateHelper from "../../../../../core/helpers/date.helper";

export const GET_COLLECTION = 'GET_COLLECTION';
export const GET_COLLECTIONS_STARTED = 'GET_COLLECTIONS_STARTED';
export const COLLECTIONS_PAGINATION_RESULT = 'COLLECTIONS_PAGINATION_RESULT';
export const COLLECTIONS_SERVER_ERROR_RESPONSE = 'COLLECTIONS_SERVER_ERROR_RESPONSE';
export const CLEAR_COUNTRY_FORM = 'CLEAR_COUNTRY_FORM';
export const COUNTRY_DELETED = 'Country Deleted Successfully';
export const COUNTRY_RESTORED = 'Country Restored Successfully';
export const CHANGE_SEARCH_COLLECTION_ATTRIBUTES = 'CHANGE_SEARCH_COLLECTION_ATTRIBUTES';
export const OPEN_CONFIRMATION_DIALOG = 'OPEN_CONFIRMATION_DIALOG';

export function paginateCollections(page, perPage, orderBy, orderDir, searchAttributes) {
  return (dispatch) => {
    dispatch({
      type: GET_COLLECTIONS_STARTED,
      payload: {}
    });
    const url = api.COLLECTIONS
    +'?page=' + page + '&per_page=' + perPage + '&sort_by=' + orderBy + '&sort_direction=' + orderDir
    +(searchAttributes.course_id ? '&course_id=' + searchAttributes.course_id : '')
    +(searchAttributes.searchKey ? '&billplz_id=' + searchAttributes.searchKey : '')
    + (searchAttributes.from_game_at ? '&game_date_from=' + dateHelper.format(searchAttributes.from_game_at, 'YYYY-MM-DD') : '')
    + (searchAttributes.to_game_at ? '&game_date_to=' + dateHelper.format(searchAttributes.to_game_at, 'YYYY-MM-DD') : '')
    + (searchAttributes.from_payout_at ? '&payout_date_from=' + dateHelper.format(searchAttributes.from_payout_at, 'YYYY-MM-DD') : '')
    + (searchAttributes.to_payout_at ? '&payout_date_to=' + dateHelper.format(searchAttributes.to_payout_at, 'YYYY-MM-DD') : '');
    HttpService.prepareGetRequest(url).execute(response => {
        dispatch({
          type: COLLECTIONS_PAGINATION_RESULT,
          payload: {
            paginationResult: response
          }
        })
      },
      error => dispatch({
        type: COLLECTIONS_SERVER_ERROR_RESPONSE,
        payload: {
          serverErrors: error['response']['data']
        }
      }));
  };
}

export function getCollection(id) {
  return dispatch => HttpService.prepareGetRequest(api.COLLECTIONS + '/' + id)
    .execute(response => {
      dispatch({
        type: GET_COLLECTION,
        payload: {
          collection: response['data']
        }
      });
    });
}

export function changeSearchCollectionsAttributes(searchAttributes) {
  console.log("change collection search attribute is called");
  console.log(searchAttributes);
  return dispatch => {
    dispatch({
      type: CHANGE_SEARCH_COLLECTION_ATTRIBUTES,
      payload: {
        searchAttributes: {...searchAttributes}
      }
    });
    //dispatch(paginateCollections(1, perPage, searchAttributes));
  };
}

export function setOpenConfirmationDialog(openConfirmationDialog, collectionId){
  return dispatch => {
    dispatch({
      type: OPEN_CONFIRMATION_DIALOG,
      payload: {
        openConfirmationDialog: openConfirmationDialog,
        collectionId: collectionId
      }
    });
  };
}

