import * as countriesActions from '../actions/countries.action';
import * as statesActions from "../../../states/store/actions/states.action";

const initialState = {
  // If edit mode
  country: null,
  countries: [],
  serverErrors: {},
  isLoading: false
};

export  const countriesReducer = function (state = initialState,action) {
  switch (action.type) {
    case countriesActions.GET_COUNTRIES_STARTED:{
      return {
        ...state,
        isLoading: true
      }
    }
    case  countriesActions.GET_COUNTRIES:{
      return{
        ...state,
        countries: action.payload.countries,
        isLoading:false
      }
    }

    case countriesActions.GET_COUNTRY: {
      return {
        ...state,
        ...action.payload
      }
    }
    case  countriesActions.COUNTRIES_SERVER_ERROR_RESPONSE:{
      return{
        ...state,
        serverErrors: action.payload.serverErrors,
        isLoading:false
      }
    }
    case countriesActions.CLEAR_COUNTRY_FORM: {
      return {
        ...state,
        country: null,
        serverErrors: {}
      };
    }
    case statesActions.CLEAR_STATES_DATA: {
      return {
        ...state,
        country: null,
        serverErrors: {}
      };
    }
    default:{
      return state;
    }
  };

}
