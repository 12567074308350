import { HttpService } from '../../../../core/service/http.service';
import { api } from '../../../../config/api';
import * as dateHelper from '../../../../core/helpers/date.helper';

export const PAGINATION_STARTED = 'PAGINATION_STARTED';
export const SLUGS_ERROR_RESPONSE =
  'SLUGS_ERROR_RESPONSE';
export const SLUGS_PAGINATION_RESULT = 'SLUGS_PAGINATION_RESULT';
export const SLUG_SERVER_ERROR_RESPONSE = 'SLUG_SERVER_ERROR_RESPONSE';
export const GET_SLUG = 'GET_SLUG';
export const EDIT_SLUG = 'EDIT_SLUG';

export function paginateSlugs(
  page,
  perPage,
  orderBy,
  orderDir,
  searchAttributes
) {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_STARTED,
      payload: {},
    });
    const url =
      api.SLUGS +
      '?page=' +
      page +
      '&per_page=' +
      perPage +
      '&orderBy=' +
      orderBy +
      '&orderDir=' +
      orderDir +
      '&country_id=' +
      searchAttributes.country_id +
      '&state_id=' +
      searchAttributes.state_id +
      '&search_key=' +
      searchAttributes.searchKey +
      '&is_active=' +
      searchAttributes.is_active +
      '&is_deleted=' +
      searchAttributes.is_deleted;
    HttpService.prepareGetRequest(url).execute(
      (response) => {
        dispatch({
          type: SLUGS_PAGINATION_RESULT,
          payload: {
            paginationResult: response,
          },
        });
      },
      (error) =>
        dispatch({
          type: SLUGS_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data']['data'],
          },
        })
    );
  };
}

export function getSlugById(id) {
  return (dispatch) => {
    HttpService.prepareGetRequest(`${api.SLUGS}/${id}`).execute(
      (response) => {
        dispatch({
          type: GET_SLUG,
          payload: {
            slug: response.data,
          },
        });
      }
    );
  };
}

export function editSlug(id, slug, successCb = null, failureCb = null) {
  console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh')
  return (dispatch) => {
    HttpService.preparePutRequest(`${api.SLUGS}/${id}`, slug).execute(
      (response) => {
        dispatch({
          type: EDIT_SLUG,
          payload: {
            slug: response['data'],
            serverErrors: {},
          },
        });
        if (successCb) {
          successCb(response);
        }
      },
      (error) => {
        dispatch({
          type: SLUG_SERVER_ERROR_RESPONSE,
          payload: {
            serverErrors: error['response']['data'],
          },
        });
        if (failureCb) {
          failureCb();
        }
      }
    );
  };
}


