import * as RequestResetActions from '../actions/reset-password.actions';

const initialState = {
  serverErrors: {}
};

export const resetPasswordReducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case RequestResetActions.REQUEST_RESET_ERROR:
    {
      return {
        ...initialState,
        ...action.payload
      };
    }
    default:
    {
      return state
    }
  }
};
