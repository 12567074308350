import * as usersActions from '../actions/users.actions';
import {table} from "../../config/config";

const initialState = {
  // If edit mode
  user: null,
  users: [],
  serverErrors: {},
  isLoading: false,
  table: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: {data: []},
    isLoading: false
  },
  searchAttributes: {
    auto_payout: 0,
    accept_payment: 0,
    send_sms: 0,
    searchKey: '',
    role_id: ''
  },
  payoutBillsTable: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: {data: []},
    isLoading: false
  }

};

export const usersReducer = function (state = initialState, action) {

  switch (action.type) {
    case usersActions.GET_USERS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    }

    case usersActions.CLEAR_USER_SEARCH_ATTRIBUTES: {
      return {
        ...state,
        searchAttributes: {
          ...initialState.searchAttributes
        }
      }
    }
    
    case usersActions.CHANGE_USER_SEARCH_ATTRIBUTES: {
      return {
        ...state,
        ...action.payload
      }
    }
    case usersActions.GET_USERS_STARTED: {
      return {
        ...state,
        isLoading: true
      }
    }
    case usersActions.GET_USER: {
      return {
        ...state,
        ...action.payload
      }
    }
    case usersActions.USERS_PAGINATION_STARTED: {
      return {
        ...state,
        table: {
          paginatorConfig: state.table.paginatorConfig,
          paginationResult: state.table.paginationResult,
          isLoading: true
        }
      }
    }

    case usersActions.USERS_PAGINATION_RESULT: {
      const {paginationResult} = action.payload;
        return {
            ...state,
            ...action.payload,
            table: {
                paginatorConfig: {
                    perPage: +paginationResult['meta']['per_page'],
                    pages: paginationResult['meta']['last_page'],
                    index: paginationResult['meta']['current_page']
                },
                paginationResult: paginationResult,
                isLoading: false

            }
        }
  }


  case usersActions.USERS_PAGINATION_ERROR_RESPONSE: {
    return {
      ...state,
      ...action.payload,
      table: {
        ...state.table,
        isLoading: false
      }
    }
  }

    case usersActions.USERS_SERVER_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case usersActions.CLEAR_USER_FORM: {
      return {
        ...state,
        user: null,
        serverErrors: {}
      };
    }
  
  
    case usersActions.PAGINATE_PAYOUT_STARTED: {
      return {
        ...state,
        payoutBillsTable: {
          paginatorConfig: state.payoutBillsTable.paginatorConfig,
          paginationResult: state.payoutBillsTable.paginationResult,
          isLoading: true
        }
      }
    }

    case usersActions.USER_PAYOUT_PAGINATION_RESULT: {
      const {paginationResult} = action.payload;
      return {
        ...state,
        ...action.payload,
        payoutBillsTable: {
          paginatorConfig: {
            perPage: +paginationResult['per_page'],
            pages: paginationResult['last_page'],
            index: paginationResult['current_page']
          },
          paginationResult: paginationResult,
        },
        isLoading: false

      }
    }

    default: {
      return state
    }
  }
};
