import {combineReducers, createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {reducers} from "./reducers";

const createReducer = () => combineReducers({
  ...reducers
});


export const store = createStore(createReducer(),  applyMiddleware(thunk));

