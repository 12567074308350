import React from 'react';
import {BaseComponent} from "../base/base.component";
import {bindActionCreators} from "redux";
import {actions} from "../../../shared/store/actions";
import {client} from "../../../config/client";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

class AuthorizationComponent extends BaseComponent {

  constructor(props) {
    super(props);
    this.state = {
      accessGranted: false
    }
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      AuthorizationComponent.navigate(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      AuthorizationComponent.navigate(this.props);
    }
  }

  /**
   * Lifecycle method that is called after the components instantiated
   * or received a new prop
   * @param nextProps of components
   * @param prevState of state
   * @return the value of new props
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    const {location, user} = nextProps;
    const {pathname} = location;
    let accessGranted = false;

    if (user) {
      accessGranted = pathname !== client.LOGIN;
    } else {
      accessGranted = pathname === client.LOGIN;
    }
    return {
      accessGranted
    };
  }

  static navigate(props) {
    const {history, user, location} = props;
    const {pathname, state} = location;

    if (user) {
      const redirectUrl = state && state['redirectUrl'] ? state['redirectUrl'] : '/';
      history.push({
        pathname: redirectUrl
      });
    } else {
      history.push({
        pathname: client.LOGIN,
        state   : {redirectUrl: pathname}
      })
    }
  }

  render() {
    const {children} = this.props;
    const {accessGranted} = this.state;
    return accessGranted ? <React.Fragment>{children}</React.Fragment> : null;
  }

}

function mapStateToProps({userReducer}) {
  return {
    user: userReducer.user
  };
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      ...actions
    },
    dispatch);
}

export const Authorization = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthorizationComponent));
