import * as UserActions from '../actions/user.actions';

const initialState = {
    user: null,
    notifications: []
};

export const userReducer = function (state = initialState, action) {
    switch ( action.type )
    {
      case UserActions.SET_USER_DATA:
        {
            return {
                ...initialState,
                ...action.payload
            };
        }
      case UserActions.GET_USER_NOTIFICATIONS:
      {
        return {
          ...state,
          ...action.payload
        };
      }
        case UserActions.REMOVE_USER_DATA:
        {
            return {
                ...initialState
            };
        }
        default:
        {
            return state
        }
    }
};
