import {table, redemptionPlatform} from "../../config/config";
import { vouchersActions } from "../actions";
import * as voucherActions from '../actions/voucher.actions'

const initialState = {
    voucher: null,
    table: {
        paginatorConfig: table.paginatorConfig,
        paginationResult: {data: []},
        isLoading: false
      },
      serverErrors: {},
      searchAttributes: {
        fromDate: null,
        toDate: null, 
        searchKey: '',
        new_user: 0,
        redemption_platform: Object.keys(redemptionPlatform)[0],
      },
      billsTable: {
        paginatorConfig: table.paginatorConfig,
        paginationResult: {data: []},
        isLoading: false
      },
      logs: []



};


export const voucherReducer = function ( state = initialState, action) {
    switch(action.type) {

        case voucherActions.VOUCHERS_PAGINATION_STARTED: {
            return {
              ...state,
              table: {
                paginatorConfig: state.table.paginatorConfig,
                paginationResult: state.table.paginationResult,
                isLoading: true
              }
            }
          }

       case voucherActions.VOUCHERS_PAGINATION_RESULT: {
           const {paginationResult} = action.payload;
            return {
                ...state,
                ...action.payload,
                table: {
                    paginatorConfig: {
                        perPage: +paginationResult['meta']['per_page'],
                        pages: paginationResult['meta']['last_page'],
                        index: paginationResult['meta']['current_page']
                    },
                    paginationResult: paginationResult,
                    isLoading: false

                }
            }
       }


       case voucherActions.VOUCHERS_PAGINATION_ERROR_RESPONSE: {
        return {
          ...state,
          ...action.payload,
          table: {
            ...state.table,
            isLoading: false
          }
        }
      }


      case voucherActions.VOUCHERS_GET_BILLS_STARTED: {
        return {
          ...state,
          billsTable: {
            paginatorConfig: state.billsTable.paginatorConfig,
            paginationResult: state.billsTable.paginationResult,
            isLoading: true
          }

        }
      }

      case voucherActions.VOUCHERS_GET_BILLS_SUCCESSFULLY: {
        const {paginationResult} = action.payload;

        return {
          ...state,
          billsTable: {
            paginatorConfig: {
              perPage: +paginationResult['meta']['per_page'],
              pages: paginationResult['meta']['last_page'],
              index: +paginationResult['meta']['current_page']
            },
            paginationResult: paginationResult,
            isLoading: false
          }



        }
      }

      case voucherActions.VOUCHERS_BILLS_ERROR_RESPONSE: {
        return {
          ...state,
          ...action.payload,
          billsTable: {
            ...state.billsTable,
            isLoading: false
          }
        }
      }

      case voucherActions.GET_VOUCHER: {
        return {
          ...state,
          ...action.payload
        }
      }

      case voucherActions.GET_VOUCHER_ERROR_RESPONSE: {
        return {
          ...state,
          ...action.payload
        }
      }

      case voucherActions.CLEAR_VOUCHER_DETAILS: {
        return {
          ...state,
          voucher: null
        }
      }



      case voucherActions.VOUCHER_SERVER_ERROR_RESPONSE: {
        return {
          ...state,
          ...action.payload,
          isLoading: false
        };
      }
      case voucherActions.EDIT_VOUCHER: {
        return {
          ...state,
          ...action.payload,
          serverErrors: {}
        }
      }




      case voucherActions.GET_VOUCHER_LOGS_SUCCESSFULLY: {
        return {
          ...state,
          ...action.payload,
        }
      }

      case voucherActions.CLEAR_VOUCHER_LOGS: {
        return {
          ...state,
          logs: []
        }
      }

      case voucherActions.VOUCHER_LOGS_SERVER_ERROR_RESPONSE: {
        return {
          ...state,
          serverErrors: action.payload
        }
      }

      case voucherActions.CLEAR_VOUCHER_SEARCH_ATTRIBUTES: {
        return {
          ...state,
          searchAttributes: {
            ...initialState.searchAttributes
          }
        }
      }
      case voucherActions.CHANGE_VOUCHER_SEARCH_ATTRIBUTES: {
        return {
          ...state,
          ...action.payload
        }
      }


      default: {
        return state;
      }

    }

}
