import * as profileActions from '../actions/profile.actions';

const initialState = {
  serverErrors: {},
  isLoading: false
};

export const profileReducer = function (state = initialState, action) {
  switch (action.type) {
    case profileActions.PROFILE_SERVER_ERROR_RESPONSE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case profileActions.GET_PROFILE_DATA_SUCCESSFULLY: {
      return {
        ...state,
        profile:action.payload
      };
    }

    default: {
      return state;
    }
  }
};
