import {
  GET_CREATORS_JOINERS,
  GET_DASHBOARD_DETAILS,
  GET_DASHBOARD_DETAILS_ERROR,
  GET_GAME_ROUNDS_PER_COUNTRY,
  GET_GAME_STATUS_PER_COUNTRY,
  GET_GAME_TYPE_PER_COUNTRY, GET_PAYMENT_STATISTICS, GET_PAYOUT_STATISTICS, GET_PAYOUT_STATISTICS_ERROR,
  GET_PLAYERS_PER_COUNTRY,
  GET_PLAYERS_PER_PROVIDERS, GET_TOTAL_PAYMENT, GET_VALUES_PAYMENT,
  GET_REFERRALS, GET_REFERRALS_ERROR,
  GET_PAID_REFERRALS,GET_PAID_REFERRALS_ERROR
} from "../actions/dashboard.actions";

const initialState = {
  serverErrors: {},
  dashboardDetails: null,
  paymentStatistics: null,
  payoutStatistics: null,
  playersPerProviders: null,
};
export const dashboardReducer = function (state = initialState, action) {

  switch (action.type) {
    case GET_DASHBOARD_DETAILS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case GET_PAYMENT_STATISTICS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case GET_PAYOUT_STATISTICS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case GET_PLAYERS_PER_PROVIDERS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case GET_CREATORS_JOINERS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case GET_PLAYERS_PER_COUNTRY: {
      return {
        ...state,
        ...action.payload
      }
    }
    case GET_GAME_STATUS_PER_COUNTRY:
    case GET_GAME_TYPE_PER_COUNTRY:
    case GET_TOTAL_PAYMENT:
    case GET_VALUES_PAYMENT:
    case GET_GAME_ROUNDS_PER_COUNTRY: {
      return {
        ...state,
        ...action.payload
      }
    }
    case GET_REFERRALS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case GET_PAID_REFERRALS: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return state
    }
  }
}
