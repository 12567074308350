import * as collectionsActions from '../actions/collections.action';
import {table} from "../../../../players/config/config";

const initialState = {
  serverErrors: {},
  table: {
    paginatorConfig: table.paginatorConfig,
    paginationResult: {data: []},
  },
  isLoading: false,
  searchAttributes: {
    course_id: '',
    searchKey: '',
    from_game_at: null,
    to_game_at: null,
    from_payout_at: null,
    to_payout_at: null,
  },
  openConfirmationDialog: false,
};


export  const collectionsReducer = function (state = initialState, action) {
  switch (action.type) {
    case collectionsActions.GET_COLLECTIONS_STARTED:{
      return {
        ...state,
        isLoading:true

      }
    }
    case  collectionsActions.COLLECTIONS_SERVER_ERROR_RESPONSE:{
      return{
        ...state,
        serverErrors: action.payload.serverErrors,
        isLoading:false
      }
    }
    case  collectionsActions.GET_COLLECTION:{
      return {
        ...state,
        ...action.payload
      }
    }
    case collectionsActions.COLLECTIONS_PAGINATION_RESULT: {
      const {paginationResult} = action.payload;
      return {
        ...state,
        ...action.payload,
        table: {
          paginatorConfig: {
            perPage: +paginationResult['meta']['per_page'],
            pages: paginationResult['meta']['last_page'],
            index: +paginationResult['meta']['current_page']
          },
          paginationResult: paginationResult,
        },
        isLoading: false
      }
    }

    case collectionsActions.CHANGE_SEARCH_COLLECTION_ATTRIBUTES: {
      return {
        ...state,
        searchAttributes: action.payload.searchAttributes
      }
    }

    case collectionsActions.OPEN_CONFIRMATION_DIALOG: {
      return {
        ...state,
        openConfirmationDialog: action.payload.openConfirmationDialog,
        collectionId: action.payload.collectionId
      }
    }
    default:{
      return state;
    }
  }

};

