import * as Sentry from '@sentry/browser';
import {BaseComponent} from "../base/base.component";
import React from "react";



const styles = {
  container: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  feedbackBtn: {
    padding: 15,
    fontSize: 20,
    color: 'darkslategray',
    marginTop: 15,
    cursor: 'pointer'
  },
  goBack: {
    display: 'block',
    marginTop: 15,
    fontSize: 16
  }
};

class ErrorBoundaryComponent extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {error: null, eventId: null};

    if ((JSON.parse(process.env.REACT_APP_RUN_SENTRY))){
      Sentry.init({
        dsn: "https://90f802717f6149ce9c38f112c4db8be1@sentry.io/1451640"
      });
    }

  }

  /**
   * Capture error, set it in state and send it
   * @param error
   * @param errorInfo
   */
  componentDidCatch(error, errorInfo) {
    this.setState({error});

    if((JSON.parse(process.env.REACT_APP_RUN_SENTRY))) {

      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({eventId})
      });
    }
  }

  render() {
    const {children} = this.props;
    if (this.state.error) {
      return (
        <div style={styles.container}>
          <h3 className="text-danger">An error happened during your session,
            report your feedback please explaining the scenario that causes this error so we can fix it ASAP!</h3>
          <a className="btn btn-warning"
             style={styles.feedbackBtn} onClick={() => Sentry.showReportDialog({eventId: this.state.eventId})}>
            Report Feedback
          </a>
          <a style={styles.goBack} href="/">Go Back</a>
        </div>
      );
    } else {
      return (
        <React.Fragment>{children}</React.Fragment>);
    }
  }
}

export const ErrorBoundary = ErrorBoundaryComponent;
