import {authReducer} from "../../../modules/authentication/store/reducers";
import {manageUsersReducer} from "../../../modules/administration/users/store/reducers";
import {gamesReducer} from "../../../modules/games/store/reducers";
import {manageRolesReducer} from "../../../modules/administration/roles/store/reducers";
import {sharedReducer} from "./shared.reducer";
import {reducer as toastrReducer} from 'react-redux-toastr'
import {manageCountriesReducer} from "../../../modules/places/countries/store/reducers";
import {manageStatesReducer} from "../../../modules/places/states/store/reducers";
import {playersReducer} from "../../../modules/players/store/reducers";
import {coursesReducer} from "../../../modules/courses/store/reducers";
import {packagesReducer} from "../../../modules/packages/store/reducers";
import {slugsReducer} from "../../../modules/slugs/store/reducers";
import {profileReducers as profileReducer} from "../../../modules/profile/store/reducers";
import {DashboardReducer} from "../../../modules/dashboard/store/reducers";
import {smsReducers as smsReducer} from "../../../modules/sms/store/reducers";
import {manageCollectionsReducer} from "../../../modules/payment/collections/store/reducers";
import {manageBillsReducer} from "../../../modules/payment/bills/store/reducers";
import {leaderBoardReducers} from "../../../modules/leaderBoards/store/reducers";
import {vouchersReducer} from "../../../modules/vouchers/store/reducers";
import {faqsReducer} from "../../../modules/faq/store/reducers";
import {failedJobsReducers} from "../../../modules/failedJobs/store/reducers";

/**
 * Export all system reducers
 */
export const reducers = {
  ...authReducer,
  ...gamesReducer,
  ...vouchersReducer,
  ...faqsReducer,
  ...coursesReducer,
  ...packagesReducer,
  ...slugsReducer,
  ...manageUsersReducer,
  ...manageRolesReducer,
  ...manageCountriesReducer,
  ...manageStatesReducer,
  toastr: toastrReducer,
  sharedReducer: sharedReducer,
  ...playersReducer,
  ...profileReducer,
  ...DashboardReducer,
  ...smsReducer,
  ...manageCollectionsReducer,
  ...manageBillsReducer,
  ...leaderBoardReducers,
  ...failedJobsReducers
};
