import {authActions} from "../../../modules/authentication/store/actions";
import {gamesActions} from "../../../modules/games/store/actions";
import {manageUsersActions} from "../../../modules/administration/users/store/actions";
import {manageRolesActions} from "../../../modules/administration/roles/store/actions";
import * as sharedActions from "./shared.actions";
import {manageCountriesActions} from "../../../modules/places/countries/store/actions";
import {manageStatesActions} from "../../../modules/places/states/store/actions";
import {playersActions} from "../../../modules/players/store/actions";
import {coursesActions} from "../../../modules/courses/store/actions";
import {packagesActions} from "../../../modules/packages/store/actions";
import {slugsActions} from "../../../modules/slugs/store/actions";
import {profileActions} from "../../../modules/profile/store/actions";
import {DashboardActions} from "../../../modules/dashboard/store/actions";
import {SmsActions} from "../../../modules/sms/store/actions";
import {manageCollectionsActions} from "../../../modules/payment/collections/store/actions";
import {manageBillsActions} from "../../../modules/payment/bills/store/actions";
import {leaderBoardsActions} from "../../../modules/leaderBoards/store/actions";
import {vouchersActions} from '../../../modules/vouchers/store/actions';
import {faqsActions} from '../../../modules/faq/store/actions';
import {failedJobsActions} from '../../../modules/failedJobs/store/actions';
/**
 * Export all system actions
 */
export const actions = {
  ...authActions,
  ...manageUsersActions,
  ...gamesActions,
  ...vouchersActions,
  ...faqsActions,
  ...packagesActions,
  ...slugsActions,
  ...coursesActions,
  ...manageRolesActions,
  ...manageCountriesActions,
  ...manageStatesActions,
  ...sharedActions,
  ...playersActions,
  ...profileActions,
  ...DashboardActions,
  ...SmsActions,
  ...manageCollectionsActions,
  ...manageBillsActions,
  ...leaderBoardsActions,
  ...failedJobsActions
};
